import React, { useEffect, useState } from 'react';
import * as XLSX from 'xlsx';
import { MwInputFile, MwSpinnerButton } from '../../ui'
import { useCurrent, usePost } from '../../../hooks';

const ImportExcel = ({callbackImported}) => {
    const [stored,setStored] = useState();
    const [data, setData] = useState([]);
    const [dataLength, setDataLength] = useState(0);
    const [loadingImport, setLoadingImport] = useState(false);
    const {currentTenantId,currentCompanyId,currentBranchId,userId} = useCurrent();
    const {data:storeResul,postData} = usePost();
    const handleFileChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
        const workbook = XLSX.read(event.target.result, { type: 'binary' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet);
        setData(jsonData);
        };
        reader.readAsArrayBuffer(file);
    };
    const storeInvoicesHandler = (_objInvoices) => {
        if(_objInvoices.length > 0){
            setDataLength(1)
            setLoadingImport(true);
            
            const promises = _objInvoices.map( (row,index) => {
                return new Promise((resolve) => {
                    setTimeout(async() => {
                        await postData(`${process.env.REACT_APP_INV_API_SERVER_BASE_URL}/invoices`,row)
                        setDataLength((state) => state + 1 )
                        setStored(row)
                        resolve();
                    },index * 2000)
                    
                });
            });

            Promise.all(promises).then(() => {
                setLoadingImport(false);
                callbackImported()
            });
        }
    }

    useEffect(() => {
        let _objInvoices = [];
        if(data.length > 0){
            console.log(data);
            let invHead =0;
            data.forEach((row,index) => {
               // if(row['ITEM_NAME']){
                    if(row['INVOICE_NO'] && row['INVOICE_NO'] !== 0 ){
                        _objInvoices[index]={
                            inv_no : row['BRANCH_CODE']+'-'+row['INVOICE_NO'],
                            inv_date : row['INVOICE_DATE'],
                            inv_ref : row['INVOICE_REF'] || null,
                            total_amount : row['T_AMOUNT'],
                            discount_amount : row['T_DISCOUNT'] || 0,
                            vat_amount : row['VAT'],
                            net_amount : row["NET_AMOUNT"],
                            type_id : 1,
                            status_id : 1,
                            is_paid : 0,
                            customer_id : 0,
                            customer_name : row["BUYER_NAME"],
                            salesman_id : 0,
                            tenant_id : currentTenantId,
                            company_id : currentCompanyId,
                            branch_id : currentBranchId,
                            created_by : userId,
                            inv_details:[],
                            zatca:{
                                zatca_inv_type : row["ZATCA_TYPE"].toLowerCase(), // simplified,simplified_debit_note,simplified_credit_note,'standard,standard_debit_note,standard_credit_note
                                branch_code: row["BRANCH_CODE"] ,
                                cat_tax: row["CAT_TAX"],
                                class_tax: row["CLASS_TAX"],
                                currency_code : row["CURRENCY"],
                                buyer_name : row["BUYER_NAME"],
                                buyer_vat_no : row["BUYER_VAT_NO"],
                                buyer_street : row["BUYER_STREET"],
                                buyer_building : row["BUYER_BUILDING"],
                                buyer_city : row["BUYER_CITY"],
                                buyer_country_code : row["BUYER_COUNTRY"],
                                buyer_postal : row["BUYER_POSTAL"],
                            }
                        }
                        row['ITEM_NAME'] && _objInvoices[index].inv_details.push({
                            product_id : row['ITEM_NAME'],
                            product_name : row['ITEM_NAME'],
                            unit_id : row['I_UNIT_CODE'] || 1,
                            qty : row['QUANTITY'],
                            price:  (row['PRICE'] / row['QUANTITY']),
                            total_price: row['PRICE'] || 0,
                            product_discount: row['DISCOUNT'] || 0,
                            product_net_total: row['PRICE'] ,
                            product_vat: row['ITEM_VAT'],
                            product_net_total_with_vat : row['ITEM_T_AMOUNT']
                        });
                        invHead = index; 
                    }else{
                        if(_objInvoices[invHead]){
                            _objInvoices[invHead].inv_details.push({
                                product_id : row['ITEM_NAME'],
                                product_name : row['ITEM_NAME'],
                                unit_id : row['I_UNIT_CODE'],
                                qty : row['QUANTITY'],
                                price:  (row['PRICE'] / row['QUANTITY']),
                                total_price: row['PRICE'] || 0,
                                product_discount: row['DISCOUNT'] || 0,
                                product_net_total: row['PRICE'] || 0,
                                product_vat: row['ITEM_VAT'],
                                product_net_total_with_vat : row['ITEM_T_AMOUNT']
                            })
                        }
                    }
               // }
            });
            console.log('obj', _objInvoices)
            storeInvoicesHandler(_objInvoices);
        }
    },[data]);

    return (
        <>
            <div className="w-80 p-2 rounded-lg flex flex-col gap-1 items-end justify-center  bg-slate-100">
                <span className="font-bold" >Import Invoices From Excel File</span>
                { loadingImport ? <div className='w-full flex flex-row justify-between items-center'>
                                        <div className='text-xs text-end'>{storeResul?.message} </div>
                                        <div className='text-xs text-center px-1'>
                                            <div className='text-center'> {dataLength} </div> 
                                            <div className='text-center font-bold  py-2'>{stored?.inv_no} </div>
                                        </div>
                                        <MwSpinnerButton withLabel={false} /> 
                                    </div>: 
                    <div className="w-full flex  gap-5 justfy-between items-center">
                        <div className="flex-1 text-xs font-bold text-violet-500"> <a target='_blank' href='https://4sharks-absat.s3.eu-north-1.amazonaws.com/zatca/zatca-invoices-simplified.xlsx' >Download Example</a> </div>
                        <MwInputFile id='file' acceptTypes='xlsx' onChangeHandler={handleFileChange} isSmall={true} />
                    </div>
                }
            </div>
        </>
    )
    
}

export default ImportExcel