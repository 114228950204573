import React, { useEffect, useState } from 'react'
import {MwButton} from '../../components/ui'
import {addCustomer} from '../../services/customerService'
import { useCurrent, useFetch, usePost } from '../../hooks'
import CardHeadeWithActions from '../ui/CardHeadeWithActions'
import BasicInfoForm from './forms/BasicInfoForm'
import ResponsiblesForm from './forms/ResponsiblesForm'
import AddressForm from './forms/AddressForm'
import AdvancedForm from './forms/AdvancedForm'
import {langObjType,objLangTextHandler} from '../../utils/global'
import {getAllSalesman} from '../../services/salesmanService';

const CreateCustomer = ({onHide ,onCustomerCreated = ()=>{} ,setMode}) => {

    const {currentTenantId,currentCompanyId,currentBranchId,currentLangList,currentLangId} = useCurrent()
    const [errors,setErrors] = useState({});
    const [tabActive,setTabActive] = useState('TabOne');
    const {data:resultPost,postData} = usePost();
    // Basic Form  
    const [fullname,setFullname] = useState(langObjType())
    const [customerNo,setCustomerNo] = useState('')
    const [groupSelected,setGroupSelected] = useState([]); // customerGroupId
    const [mobileNo,setMobileNo] = useState('')
    const [phoneNo,setPhoneNo] = useState();
    const [email,setEmail] = useState('')
    const [customerNotes,setCustomerNote] = useState('')
    const [isActive,setIsActive] = useState(true)
    const [customerType,setCustomerType] = useState([])
    const [paymentDuringDays,setPaymentDuringDays] = useState()
    const [salesmanSelected,setSalesmanSelected] = useState();
    const [listPriceSelected,setListPriceSelected] = useState()
    const [listPrice,setListPrice] = useState([])
    const {data:resultPriceList,loading:loadingPriceList} =useFetch(`${process.env.REACT_APP_INV_API_SERVER_BASE_URL}/inventory/list-price/${currentTenantId}/${currentCompanyId}/${currentBranchId}`)

    // Rresponsibles Form
    const [responsibles,setResponsibles] = useState([]);
    // Addresses Form
    const [address,setAddress] = useState([]);
    // Advanced Form
    const [customerAccountNo,setCustomerAccountNo] = useState()
    const [customerVatNo,setCustomerVatNo] = useState()
    const [limitCredit,setLimitCredit] = useState();
    const custTypesList=[
        {
            label:'شركات',
            value:1 ,
            lang: "ar"
        },
        {
            label:'Company',
            value:1 ,
            lang: "en"
        },
        {
            label:'افراد',
            value:2,
            lang: "ar"
        },
        {
            label:'Individuals',
            value:2,
            lang: "en"
        }
    ]
    const [_salesmansList,_setSalesmanList] = useState([]); 
    
    const getSallesman = async () => {
        const _salesman = await getAllSalesman(currentTenantId ,currentCompanyId,currentBranchId)
        _setSalesmanList(_salesman)
        console.log(_salesman)
    } 

    useEffect(() => {
        getSallesman();
    },[]);
    useEffect(() =>{
        if(resultPriceList){
            const _list = resultPriceList?.data?.map(item => ({
                label: item.name,
                value: item.id,
                lang: currentLangId
            }))
            setListPrice(_list)
        }
    },[resultPriceList]);

    const formValidate = () => {
        let errorObj = {};

        if(!fullname[currentLangId]){
            errorObj = { ...errorObj, [`fullname.${currentLangId}`] : 'يجب ادخال اسم العميل'};
            
        }

        if(isNaN(mobileNo)){
            errorObj.mobileNo = 'رقم الجوال يجب ان يكون ارقام';
        }
        if(Object.keys(errorObj).length !== 0 ){
            setErrors(errorObj)
            console.log('errors',errors)
            return true
        }
        return false;
    }
    const saveHandler = async() => { 
        // e.preventDefault();
        if(formValidate()) {
            return  ;
        }
        const _data={
            fullname: objLangTextHandler(fullname),
            customerNo,
            mobileNo,
            phoneNo,
            email,
            customerNotes,
            limitCredit,
            customerAccountNo,
            customerVatNo,
            customerGroupsIds: groupSelected.map(el => el.value),
            responsibles:responsibles,
            customerAddresses:address,
            customerType:customerType?.value,
            paymentDuringDays:paymentDuringDays,
            salesmanId:salesmanSelected?.value,
            listPriceId: listPriceSelected?.value,
            isActive,
            tenantId:currentTenantId,
            companyId:currentCompanyId,
            branchId:currentBranchId
        }

        postData(`${process.env.REACT_APP_Auth_API_BASE_URL}/customers`,_data)

        
    };
    const cancelHandle = () => {
        onHide();
    }

    useEffect(()=>{
        if(resultPost){
            console.log('added',resultPost)
            onHide ? cancelHandle() : setMode('List');
            onCustomerCreated();
        }
    },[resultPost]);
    
    return (
        <div className={`${onHide && 'p-5'}`} >
            <CardHeadeWithActions title={`أضف عميل جديد`}>
                { onHide && <MwButton inGroup={true}  type='cancelBtn' onClick={cancelHandle}>الغاء</MwButton> }
                <MwButton inGroup={true} type='cancelBtn' onClick={() => setMode('List')}  >
                    ادارة العملاء
                </MwButton>
                    <MwButton actionType={`button`} inGroup={true} onClick={saveHandler}   type='saveBtn' >
                    حفظ العميل
                    </MwButton>
            </CardHeadeWithActions>
            <form  >
                <div className=' bg-slate-50 rounded-lg text-xs p-3 flex flex-col gap-1'>

                    <ul className='flex gap-3 items-centers'>
                        <li onClick ={()=>setTabActive('TabOne')} className={`cursor-default rounded px-2 py-1 hover:font-bold ${tabActive==='TabOne' ? 'bg-indigo-100 font-bold' : 'bg-slate-100'}`}>المعلومات الاساسية</li>
                        <li onClick ={()=>setTabActive('TabTwo')} className={`cursor-default rounded px-2 py-1 hover:font-bold ${tabActive==='TabTwo' ? 'bg-indigo-100 font-bold': 'bg-slate-100'}`} >بيانات المسئول</li>
                        <li onClick ={()=>setTabActive('TabThree')} className={`cursor-default rounded px-2 py-1 hover:font-bold ${tabActive==='TabThree' ? 'bg-indigo-100 font-bold': 'bg-slate-100'}`} >العناوين</li>
                        <li onClick ={()=>setTabActive('TabFour')} className={`cursor-default rounded px-2 py-1 hover:font-bold ${tabActive==='TabFour' ? 'bg-indigo-100 font-bold': 'bg-slate-100'}`} >بيانات متقدمة</li>
                    </ul>
                    {
                        tabActive === 'TabOne' && 
                        <BasicInfoForm
                            _salesmansList = {_salesmansList}
                            _setSalesmanList = {_setSalesmanList}
                            custTypesList = {custTypesList}
                            customerType = {customerType}
                            setCustomerType = {setCustomerType}
                            salesmanSelected = {salesmanSelected}
                            setSalesmanSelected = {setSalesmanSelected}
                            currentTenantId = {currentTenantId} 
                            currentCompanyId = {currentCompanyId}
                            currentBranchId = {currentBranchId}
                            currentLangId = {currentLangId}
                            currentLangList = {currentLangList}
                            errors = {errors}
                            customerNo = {customerNo}
                            setCustomerNo = {setCustomerNo}
                            groupSelected = {groupSelected}
                            setGroupSelected = {setGroupSelected}
                            fullname = {fullname}
                            setFullname = {setFullname}
                            mobileNo = {mobileNo}
                            setMobileNo = {setMobileNo}
                            phoneNo = {phoneNo}
                            setPhoneNo = {setPhoneNo}
                            email  = {email}
                            setEmail = {setEmail}
                            customerNotes = {customerNotes}
                            setCustomerNote = {setCustomerNote}
                            isActive = {isActive}
                            setIsActive = {setIsActive}
                            /> 
                    }
                    {
                        tabActive === 'TabTwo' &&
                        <ResponsiblesForm
                            currentLangId = {currentLangId}
                            currentLangList = {currentLangList}
                            errors = {errors}
                            responsibles = {responsibles}
                            setResponsibles = {setResponsibles}
                            />
                    }
                    {
                        tabActive === 'TabThree' &&
                        <AddressForm 
                            errors ={errors}
                            address = {address}
                            setAddress = {setAddress}
                            />
                    }
                    {
                        tabActive === 'TabFour' &&
                        <AdvancedForm
                            errors = {errors}
                            customerAccountNo = {customerAccountNo}
                            setCustomerAccountNo = {setCustomerAccountNo}
                            customerVatNo = {customerVatNo}
                            setCustomerVatNo = {setCustomerVatNo}
                            limitCredit = {limitCredit}
                            setLimitCredit = {setLimitCredit}
                            paymentDuringDays = {paymentDuringDays}
                            setPaymentDuringDays = {setPaymentDuringDays}
                            listPriceSelected = {listPriceSelected}
                            setListPriceSelected = {setListPriceSelected}
                            listPrice = {listPrice}
                            />
                    }
                </div>
            </form>
        </div>
    )
}

export default CreateCustomer