import React from 'react'
import { MwInputText, MwSelector } from '../../ui'

const AdvancedForm = ({
    errors,
    customerAccountNo,
    setCustomerAccountNo,
    customerVatNo,
    setCustomerVatNo,
    limitCredit,
    setLimitCredit,
    paymentDuringDays,
    setPaymentDuringDays,
    listPriceSelected,
    setListPriceSelected,
    listPrice,
    
}) => {
    
    return (
        <>
            <div className='flex gap-4'>
                <div className='flex-1 py-2'>
                    <MwInputText 
                        label='الرقم الضريبي'
                        id='customerVatNo' 
                        value={customerVatNo} 
                        onChange={(e)=>setCustomerVatNo(e.target.value)} />
                </div>
                <div className='flex-1 py-2'>
                    <MwInputText 
                        label='رقم حساب العميل'
                        id='customerAccountNo' 
                        value={customerAccountNo} 
                        onChange={(e)=>setCustomerAccountNo(e.target.value)} />
                </div>
            </div>
            
            <div className='py-2 flex gap-4 items-center '>
                <div className='flex-1'>
                    <MwInputText 
                        label='الحد الائتماني'
                        id='limitCredit' 
                        inputType='Number'
                        value={limitCredit} 
                        invalid={!!errors.limitCredit}
                        invalidMsg={errors.limitCredit}
                        onChange={(e)=>setLimitCredit(e.target.value)} />
                </div>
                <div className='flex-1 flex gap-1'>
                    <div className='flex-1'>
                        <MwInputText 
                            label='فترة السداد خلال'
                            id='limitCredit' 
                            inputType='Number'
                            value={paymentDuringDays} 
                            invalid={!!errors.paymentDuringDays}
                            invalidMsg={errors.paymentDuringDays}
                            onChange={(e)=>setPaymentDuringDays(e.target.value)} />
                    </div>
                    <div className='w-8 flex items-center justify-center pt-3'>يوم</div>
                </div>
                <div className='flex-1 py-2 relative'>
                    {
                    <MwSelector
                        label={`قائمة الاسعار`}
                        initalValue = {`اختار قائمة الاسعار`}
                        _data={listPrice}
                        dataType='labelValue'
                        withAddNew = {false}
                        setSelectedItem = {setListPriceSelected}
                        selectedItem = {listPriceSelected}
                        />
                    }
                </div>
            </div>
        </>
    )
}

export default AdvancedForm