import { useState } from 'react';
import axios from 'axios';

const usePut = () => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [payload, setpayload] = useState(0);

    const putData = async (url, payload) => {
        setLoading(true);
        setError(null);
        const token = localStorage.getItem('token');
        try {
            const response = await axios.put(url, payload,{
                headers:{
                    'Authorization': `Bearer ${token}` 
                }
            });
            setData(response.data);
            setLoading(false);
            setpayload(payload);
            console.log('Post Request:',`URL:` ,url, `PAYLOAD:`,payload,`RESPONE:`,response.data);
        } catch (error) {
            setError(error);
            setLoading(false);
        }
    };

    return { data, loading, error, putData,payload };
};

export default usePut;

