import {  useTranslation } from 'react-i18next';

const InvoiceHeader = ({
    logo,
    companyName,
    companyPhone,
    companyEmail,
    companyDesc,
    vatNo,
    commercialRegisteration,
    inv_no,
    inv_title,
    inv_title_ar,
    inv_date,
    inv_date_due,
    companyAddress,
    invoiceSettings
}) => {

    const [t] = useTranslation('global')
    console.log('invoiceSettings',invoiceSettings);
    return (
        <div className='pt-5' >
            {  1==2 && <div className='flex justify-between gap-24'>
                <div className='flex-1 content-center gap-1 '>
                    {invoiceSettings?.SHOW_LOGO_ON_PRINT === 'true' && <div><img src={logo} className='w-full' /></div> }
                    
                </div>
                <div className='flex-1  content-center justify-center ' dir='rtl' >
                        <div className='border p-2 font-bold py-2 text-sm' >{companyName}</div>
                        <div className='flex flex-col  text-xs text-gray-500'>
                            {/* <div>{companyDesc}</div> */}
                            <div className='border p-2 flex items-center justify-between gap-3'>
                                <span>   سجل تجاري:</span>
                                <span className='font-bold'>{commercialRegisteration}</span>
                                <span>:.C.R </span>
                            </div>
                            <div className='border p-2 flex items-center justify-between gap-3'>
                                <span>  الرقم الضريبي:</span>
                                <span className='font-bold'>{vatNo}</span>
                                <span>:VAT NO</span>
                            </div>
                            {/* { companyPhone && <div className='flex items-center justify-between gap-3'>
                                <span>   الهاتف:</span>
                                <span className='font-bold'>{companyPhone}</span>
                                <span>:Phone </span>
                            </div>}
                            { companyEmail && <div className='flex items-center justify-between gap-3'>
                                <span>  البريد الالكتروني:</span>
                                <span className='font-bold'>{companyEmail}</span>
                                <span>:EMAIL</span>
                            </div>} */}
                            { companyAddress && <div className='border p-2 flex items-center justify-between gap-3'>
                                <span>   العنوان:</span>
                                <span className='font-bold'>{companyAddress}</span>
                                <span>:Address</span>
                            </div>}
                        </div>
                </div>
            </div> }
            {/* <div  dir='rtl' className='w-full flex gap-5 justify-between  p-2 font-bold text-lg'> <span> عرض سعر  </span> <span>Quotation Proposal</span> </div> */}
            {inv_title &&  
                <div className='  flex-1 flex items-center justify-center gap-5   '> 
                {/* <div className='flex flex-col text-center'>
                    <span> اسم المشروع </span>
                    <span>  PROJECT NAME</span>
                </div> */}
                <div className='flex flex-col text-center'>
                    <span className='  font-bold '>{inv_title_ar}</span>
                    <span className=' font-bold '>{inv_title}</span>
                </div>
                </div>
            }
            {/* <hr className='mx-2 my-2'/>           */}
            <div  className='flex justify-between text-xs rounded border   mt-3 '>
                <div className='border-e flex-1 flex items-center justify-between gap-3 p-2  '> 
                <div className='flex flex-col text-center'>
                    <span> رقم العرض</span>
                    <span>QUOTATION NO</span>
                </div>
                    <span className='font-bold'>{inv_no}</span>
                </div>
                <div className=' flex-1 flex items-center justify-between gap-3 p-2 '> 
                    <div className='flex flex-col text-center'>
                        <span>تاريخ الاصدار</span>
                        <span>ISSUE DATE</span>
                    </div>
                    <span className='font-bold'>{inv_date.substring(0,10)}</span>
                </div>
                
                {/* <div className=' flex-1 flex items-center justify-between gap-3 p-2 '> 
                    <div className='flex flex-col text-center'>
                        <span>تاريخ الاستحقاق</span>
                        <span>DUE DATE</span>
                    </div>
                    <span className='font-bold'>{inv_date_due}</span>
                </div> */}
            </div>
            {/* <hr className='mx-2 mt-2'/>   */}
        </div>
    )
}

export default InvoiceHeader