import React from 'react' 
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
// React Icons
import {MdOutlineAddBox} from 'react-icons/md'
import {TbFileInvoice} from 'react-icons/tb'
import {FiSettings} from 'react-icons/fi'
import {AiOutlineDashboard} from 'react-icons/ai'
import {LuLayoutDashboard} from 'react-icons/lu'
import {CiDiscount1} from 'react-icons/ci'
import {HiOutlineDocumentReport} from 'react-icons/hi'
import {PiUsersThreeThin} from 'react-icons/pi'
import {LiaUsersCogSolid,LiaUsersSolid} from 'react-icons/lia'
import {RiQuoteText} from 'react-icons/ri'
import { RiMoneyDollarCircleLine } from "react-icons/ri";

// Custom hooks
import { useTenant } from '../../hooks'

const MenuSide = ({openMenu,setOpenMenu}) => {
    const [t] = useTranslation('global')
    const {tenantUsername} = useTenant();
    return (
        <div 
            // onMouseOver={()=>setOpenMenu(true)}
            className={`  z-50   flex items-center   transition-all duration-500  ease-out  h-screen      primary-bg-color border-b    ${openMenu ? 'fixed px-3 w-9 flex md:w-40 md:relative ' : 'fixed w-0 hidden md:flex md:w-14 md:px-0  '}`}>
                <ul className=' cursor-default  flex  flex-col gap-5 items-center  text-slate-600  text-xs  '>
                    <li className={`w-full   border-indigo-100/50  border-b pb-1 hover:text-indigo-600 ${ openMenu || 'justify-center' } `}>
                        <NavLink to={`/${tenantUsername}`} className={`flex items-center gap-2 `} end >
                            <AiOutlineDashboard size={20}  title={t('menu.Dashboard')} />
                            { openMenu && <span className='whitespace-nowrap hidden md:flex'>{t('menu.Dashboard')}</span>}
                        </NavLink>
                    </li>
                    <li className={`w-full border-indigo-100/50  border-b pb-1 hover:text-indigo-600 ${ openMenu || 'justify-center' } `}>
                        <NavLink to={`/${tenantUsername}/invoices/create`} className={`flex items-center gap-2 `} end >
                            <MdOutlineAddBox size={20} title={t('menu.CreateInvoice')}  />
                            { openMenu && <span className='whitespace-nowrap hidden md:flex'>{t('menu.CreateInvoice')}</span>}
                        </NavLink>
                    </li>
                    <li className={` w-full gap-2 flex items-center  border-indigo-100/50  border-b pb-1 hover:text-indigo-600 ${ openMenu || 'justify-center' } `}>
                        <NavLink to={`/${tenantUsername}/invoices`} className={`flex items-center gap-2 `} end >
                            <TbFileInvoice size={20} title={t('menu.Invoices')}/>
                            { openMenu && <span className='whitespace-nowrap hidden md:flex'>{t('menu.Invoices')}</span>}
                        </NavLink>
                    </li>
                    <li className={` w-full gap-2 flex items-center  border-indigo-100/50  border-b pb-1 hover:text-indigo-600 ${ openMenu || 'justify-center' } `}>
                        <NavLink to={`/${tenantUsername}/invoices-zatca`} className={`flex items-center gap-2 `} end >
                            <img src='/assets/icons/zatca-logo.png' className='max-w-6 opacity-75  hover:opacity-100' alt={'zatca'} />
                            { openMenu && <span className='whitespace-nowrap hidden md:flex'>{'Zatca'}</span>}
                        </NavLink>
                    </li>
                    <li className={`w-full border-indigo-100/50  border-b pb-1 hover:text-indigo-600 ${ openMenu || 'justify-center' } `}>
                        <NavLink to={`/${tenantUsername}/quotes`} className={`flex items-center gap-2 `} end >
                            <RiQuoteText size={20} title='عروض الاسعار'  />
                            { openMenu && <span className='whitespace-nowrap hidden md:flex'>عروض الاسعار</span>}
                        </NavLink>
                    </li>
                    <li 
                        className={` w-full gap-2 flex items-center  border-indigo-100/50  border-b pb-1 hover:text-indigo-600 ${ openMenu || 'justify-center' } `}>
                        <NavLink to={`/${tenantUsername}/products-home`} className={`flex items-center gap-2 `} end >
                            <LuLayoutDashboard size={20} title={t('menu.Products')}/>
                            { openMenu && <span className='whitespace-nowrap hidden md:flex'>المنتجات والخدمات</span>}
                        </NavLink>
                    </li>
                    <li 
                        className={` w-full gap-2 flex items-center  border-indigo-100/50  border-b pb-1 hover:text-indigo-600 ${ openMenu || 'justify-center' } `}>
                        <NavLink to={`/${tenantUsername}/transactions`} className={`flex items-center gap-2 `} end >
                            <RiMoneyDollarCircleLine size={20} title={`المدفوعات والمقبوضات`}/>
                            { openMenu && <span className='whitespace-nowrap hidden md:flex'> المدفوعات والمقبوضات </span>}
                        </NavLink>
                    </li>
                    <li 
                        className={` w-full gap-2 flex items-center  border-indigo-100/50  border-b pb-1 hover:text-indigo-600 ${ openMenu || 'justify-center' } `}>
                        <NavLink to={`/${tenantUsername}/customers`} className={`flex items-center gap-2 `} end >
                            <LiaUsersCogSolid size={20} title={t('menu.Customers')} />
                            { openMenu && <span className='whitespace-nowrap hidden md:flex'>{t('menu.Customers')}</span>}
                        </NavLink>
                    </li>
                    <li 
                        className={` w-full gap-2 flex items-center  border-indigo-100/50  border-b pb-1 hover:text-indigo-600 ${ openMenu || 'justify-center' } `}>
                        <NavLink to={`/${tenantUsername}/salesmans`} className={`flex items-center gap-2 `} end >
                            <LiaUsersSolid size={20} title={t('menu.Salesmans')} />
                            { openMenu && <span className='whitespace-nowrap hidden md:flex'>{t('menu.Salesmans')}</span>}
                        </NavLink>
                    </li>
                    {/* <li 
                        className={` w-full gap-2 flex items-center  border-indigo-100/50  border-b pb-1 hover:text-indigo-600 ${ openMenu || 'justify-center' } `}>
                        <CiDiscount1 size={20} title={t('menu.Promotions')}/>
                        { openMenu && <span className='whitespace-nowrap hidden md:flex'>{t('menu.Promotions')}</span>}
                    </li> */}
                    <li 
                        className={` w-full gap-2 flex items-center  border-indigo-100/50  border-b pb-1 hover:text-indigo-600 ${ openMenu || 'justify-center' } `}>
                        <NavLink to={`/${tenantUsername}/reports`} className={`flex items-center gap-2 `} end >
                            <HiOutlineDocumentReport size={20} title={t('menu.Reports')} />
                            { openMenu && <span className='whitespace-nowrap hidden md:flex'>{t('menu.Reports')}</span>}
                        </NavLink>
                    </li>
                    <li 
                        className={` w-full gap-2 flex items-center  border-indigo-100/50  border-b pb-1 hover:text-indigo-600 ${ openMenu || 'justify-center' } `}>
                        <NavLink to={`/${tenantUsername}/settings`} className={`flex items-center gap-2 `} end >
                            <FiSettings size={20} title={t('menu.Settings')}/>
                            { openMenu && <span className='whitespace-nowrap hidden md:flex'> إعدادات النظام </span>}
                        </NavLink>
                    </li>
                    <li 
                        className={` w-full gap-2 flex items-center  border-indigo-100/50  border-b pb-1 hover:text-indigo-600 ${ openMenu || 'justify-center' } `}>
                        <PiUsersThreeThin size={20} title={t('menu.Users')}/>
                        { openMenu && <span className='whitespace-nowrap hidden md:flex'>{t('menu.Users')}</span>}
                    </li>
                </ul>
        </div>
    )
}

export default MenuSide