import React, { useState, useEffect } from 'react';
import MwButton from '../ui/MwButton';
import { useCurrent, usePost } from '../../hooks';
import { useTenant } from '../../hooks';
 import { FaRegSave } from "react-icons/fa";
  import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AiOutlineDelete } from 'react-icons/ai';
import ToggleSwitch from '../OsNewWeight/OsToggleSwitch';

const OsCreateListPrice = ({ setMode }) => {
    const { postData, data, loading, error } = usePost(true);
    const { tenantId } = useTenant();
    const [templateName1, setTemplateName1] = useState("");
    const [isActive, setIsActive] = useState(false);
    const [errorMessages, setErrorMessages] = useState({});
    const {currentTenantId,currentCompany,currentCompanyId,currentBranchId,userId}= useCurrent()

    //  useEffect لمراقبة data و error
    useEffect(() => {
        if (data?.status === 1) {
            toast.success("تم الحفظ بنجاح", {
                style: {
                    backgroundColor: '#e5e7eb',
                    color: '#374151',
                    padding: '1rem',
                    borderRadius: '0.5rem',
                    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
                }
            });
    
            // تفريغ الحقول بعد الإضافة بنجاح
            setTemplateName1("");
            setIsActive(false);
            setErrorMessages({});
    
            // setMode('List'); // الرجوع إلى قائمة الوحدات بعد الحفظ
        } else if (error) {
            toast.error("حدث خطأ أثناء الحفظ", {
                style: {
                    backgroundColor: '#e5e7eb',
                    color: '#374151',
                    padding: '1rem',
                    borderRadius: '0.5rem',
                    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
                }
            });
        }
    }, [data, error, setMode]);
 

    

    const validateForm = () => {
        let errors = {};
        if (!templateName1.trim()) errors.templateName1 = "اسم القائمة مطلوبة";
        setErrorMessages(errors);
        return Object.keys(errors).length === 0;
    };

    const handleSave = async () => {
        if (!tenantId) {
            toast.error("لم يتم العثور على tenantId", {
                style: {
                    backgroundColor: '#e5e7eb',
                    color: '#374151',
                    padding: '1rem',
                    borderRadius: '0.5rem',
                    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
                }
            });
            return;
        }

        if (!validateForm()) {
            toast.error("يرجى ملء جميع الحقول المطلوبة", {
                style: {
                    backgroundColor: '#e5e7eb',
                    color: '#374151',
                    padding: '1rem',
                    borderRadius: '0.5rem',
                    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
                }
            });
            return;
        }

        const payload = {
            tenant_id: currentTenantId,
            company_id: currentCompanyId,
            branch_id: currentBranchId,
            name: templateName1,
            is_active: isActive ? 1 : 0,
           
        };

        try {
            await postData(`${process.env.REACT_APP_INV_API_SERVER_BASE_URL}/inventory/list-price`, payload);
            console.log('list price',payload)
        } catch (error) {
            console.error("Error saving data:", error);
        }
    };
    const handleCancel = () => {
        setMode('List');
    };

    return (
        <div className="bg-gray-100 p-6 rounded-lg shadow-md">
            <ToastContainer
                toastClassName="bg-gray-100 text-gray-900"
                bodyClassName="bg-gray-100 text-gray-900"
                progressClassName="bg-gray-300"
            />
            <div className="w-full   flex flex-row-reverse mb-6  ">
            <MwButton 
        actionType="button" 
        size="sm" 
        inGroup={true} 
        type="deleteBtn" 
        onClick={handleCancel}
    >
        <AiOutlineDelete className="ml-1" />
        إلغاء
    </MwButton>
            <MwButton
                    actionType="button"
                       size="sm"
                    inGroup={true}
                    type="original-right"
                    onClick={handleSave}
                    disabled={loading} // تعطيل الزر أثناء التحميل
                >
                    {loading ? (
                        <span>جار الحفظ...</span> // عرض "جار الحفظ" أثناء التحميل
                    ) : (
                        <>
                            <FaRegSave />
                            <span>حفظ</span>
                        </>
                    )}
                </MwButton>
            </div>

            <label htmlFor="templateName" className="block text-sm text-gray-600 mb-1">اسم القائمة</label>
            <div className="gap-4 flex justify-between mb-4">
                <div className="block w-[50%]">
                    <input id="templateName" type="text" value={templateName1} onChange={(e) => setTemplateName1(e.target.value)} className="w-full p-2 border border-gray-300 rounded-md" />
                    {errorMessages.templateName1 && <p className="text-red-500 text-sm">{errorMessages.templateName1}</p>}
                </div>
                <ToggleSwitch id="isActive" label="نشط" checked={isActive} onChange={(e) => setIsActive(e.target.checked)} />
            </div>

            

             

          
        </div>
    );
};

export default OsCreateListPrice;;;