import { useState } from 'react';
import OsViewWeights from '../../components/OsNewWeight/OsViewWeights';
import OsCreateWeight from '../../components/OsNewWeight/OsCreateWeight';
import OsEditWeight from '../../components/OsNewWeight/OsEditWeight';
import OsUnitDetails from '../../components/OsNewWeight/OsUnitDetails';

const OSALLCAT = () => {
    const [mode, setMode] = useState('List');
    const [idSelected, setIdSelected] = useState();

    return (
        <>
            <div className="p-3">
                {mode === 'List' && <OsViewWeights idSelected={idSelected} setIdSelected={setIdSelected} setMode={setMode} />}
                {mode === 'Add' && <OsCreateWeight setMode={setMode} />}
                {mode === 'Edit' && idSelected && <OsEditWeight id={idSelected} setMode={setMode} />}
                {mode === 'Details' && idSelected && <OsUnitDetails id={idSelected} setMode={setMode} />}
            </div>
        </>
    );
}

export default OSALLCAT;