import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import parse from 'html-react-parser';

import Blocks from 'editorjs-blocks-react-renderer';
// import Blocks from 'editorjs-blocks-react-renderer';

const InvoiceFooter = ({
    totalAmount,
    vatAmount,
    discountAmount,
    netAmount,
    invTerms,
    SETTINGTerms,
    SETTING_PRICE_INCLUDE_VAT,
    SETTING_INV_VAT_PERCENT,
    SETTING_SHOW_TERMS_ON_PRINT,
    isDelivery,
    currentLang,
    SETTING_INV_CURRENCY,
    formatter,
    invNotes
}) => {

    const [t] = useTranslation('global')

    useEffect(()=>{
      console.log(invNotes);
    },[invNotes]);

    return (
        <>

{ !isDelivery &&
        <div > 
            <div className='my-3 border rounded text-xs'>
                
                {
                    discountAmount ? 
                    <div className='flex-col'>
                        <div className='flex justify-between px-3 py-2 border-b'>
                            <div className='flex flex-col'>
                                <span>الإجمالي قبل الخصم </span>
                                <span>TOTAL BEFORE DISCOUNT</span>
                            </div>
                            <div className='flex flex-col justify-center '>{ formatter.format(totalAmount)  } </div>
                        </div>
                        <div className='flex justify-between px-3 py-2 border-b'>
                            <div className='flex flex-col'>
                                <span> الخصم </span>
                                <span>DISCOUNT</span>
                            </div>
                            <div className='flex flex-col justify-center '>{formatter.format(discountAmount)}</div>
                        </div>
                        <div className='flex justify-between px-3 py-2 border-b'>
                            <div className='flex flex-col'>
                            <span> الإجمالي بعد الخصم </span>
                            <span> TOTAL AFTER DISCOUNT </span>
                        </div>
                            <div className=''>{formatter.format(totalAmount - discountAmount )} </div>
                        </div>
                    </div> : 
                    <div className='flex justify-between px-3 py-2 border-b'>
                        <div className='flex flex-col'>
                            <span>الإجمالي الفرعي </span>
                            <span>SUB TOTAL</span>
                        </div>
                        <div className='flex flex-col justify-center '>{formatter.format(totalAmount)}  </div>
                    </div>
                }
                <div className='flex justify-between px-3 py-2 border-b'>
                    <div className='flex flex-col'>
                            <span> ضريبة القيمة المضافة ({SETTING_INV_VAT_PERCENT}%)</span>
                            <span>VAT ({SETTING_INV_VAT_PERCENT}%)</span>
                        </div>
                    <div className='flex flex-col justify-center '>{formatter.format(vatAmount)} </div>
                </div>
                <div className='flex justify-between px-3 py-2 border-b'>
                    <div className='flex flex-col'>
                            <span>الإجمالي شامل الضريبة </span>
                            <span>TOTAL</span>
                        </div>
                    <div className='font-bold flex flex-col justify-center '>{formatter.format(netAmount)}  </div>
                </div>
            </div>
            {/* <div className='pt-2 flex flex-col justify-center items-center'>
                            { SETTING_PRICE_INCLUDE_VAT === 'true' ?
                                <div className='flex justify-center items-center text-xs text-slate-400 '>{t('invoice.priceIncludesVat')}</div> : 
                                <div className='text-xs text-slate-400 text-center'>{t('invoice.priceNotIncludesVat')}</div> 
                                
                            }
                        </div> */}
        </div>}
        {invNotes && <div className='p-2 my-4 leading-7' >  { (invNotes.includes("{") ) ? <Blocks data={JSON.parse(invNotes) } />  : invNotes && parse(invNotes.replace('""',''))  } </div>}
        <div className='page text-sm' >
        { 
            SETTING_SHOW_TERMS_ON_PRINT === 'true'  && 
            <div className=' leading-5 p-2' dir='rtl'>
                    { (( SETTINGTerms || SETTINGTerms?.includes("{") ) ) && <div className='flex justify-between'><h1>الشروط والاحكام</h1><h1>CONDITION AND TERMS </h1></div> }
                    <div className='  '> { (SETTINGTerms?.includes("{") )  ?  <Blocks data={JSON.parse(SETTINGTerms) } /> : SETTINGTerms && parse(SETTINGTerms?.replace('""','')) } </div>
                    <div className=' '> { (invTerms?.includes("{")  ) ?  <Blocks data={JSON.parse(invTerms) } /> : invTerms && parse(invTerms?.replace('""',''))  } </div>
            </div>
        }
            <div dir='rtl'>
                <div  className='p-2 my-8 font-bold  '>Signature and approval </div>
                <div className='px-2    '> ------------------------------------ </div>
            </div>
        </div>
        
        </>
    )
}

export default InvoiceFooter