import React from 'react'
import {  useTranslation } from 'react-i18next';

const InvoiceBody = ({
    customerName,
    customer,
    salesmanName,
    invProducts,
    isDelivery,
    SETTING_INV_CURRENCY,
    formatter,
    SETTING_SHOW_INVOICE_PRODUCT_DESCRIPTION,
    SETTING_INV_QTY_DAYS,
    SETTING_INV_SHOW_ITEM_T_A_VAT,
    SETTING_INV_UOM_SHOW
    
}) => {
    console.log('customer => ',customer);
    const [t] = useTranslation('global')
    const invProductsList = invProducts.map((prod) => (
        
            prod.product_id === "section" ? 
            <div key={prod.id} className={`flex justify-between border-b `}>
                <div className='flex-1 p-2 border-e font-bold bg-slate-100'>{prod.product_name} </div>
            </div>
            :
            <div key={prod.id} className={`flex justify-between border-b border-s border-e  ${ prod.is_returned && 'bg-orange-50'}`}>
                <div className='flex-1 p-2 border-e p-2'>{prod.product_name} {prod.is_returned && <span className='font-bold text-orange-300'>[مرتجع]</span> } 
                {
                SETTING_SHOW_INVOICE_PRODUCT_DESCRIPTION === 'true' && 
                    <div className='text-xs text-slate-400 px-1'>
                        {prod?.product_desc} 
                    </div>
                }
                </div>
                <div className='w-12 flex items-center justify-center text-center border-e p-2 '>{prod.qty}</div>
                { SETTING_INV_UOM_SHOW === 'true' && <div className='w-12 flex items-center justify-center text-center border-e p-2'>{prod?.uom_relation?.short_name}</div> }
                { SETTING_INV_QTY_DAYS === 'true' && <div className='w-12 flex items-center justify-center text-center border-e p-2'>{prod.qty_days}</div> }
                {!isDelivery && <div className='w-20 flex items-center justify-center text-center  border-e p-2'>{formatter.format(prod.price)} </div>}
                {!isDelivery && <div className='w-24 flex items-center justify-center  text-center border-e p-2'>{formatter.format(prod.total_price)} </div>}
                {!isDelivery && <div className='w-16 flex items-center justify-center text-center border-e p-2'>{prod.product_discount && "%"+ Math.round((prod.product_discount / prod.total_price) * 100 ) } </div>}
                {!isDelivery && <div className='w-24 flex items-center justify-center text-center p-2'>{formatter.format(prod.product_net_total)} </div>}
                {/* {!isDelivery && <div className='w-24 text-center border-e p-2'>{formatter.format(prod.product_vat).slice(0,-6)} </div>} */}
                {!isDelivery && SETTING_INV_SHOW_ITEM_T_A_VAT === "true" && <div className='w-24 flex items-center justify-center  text-center border-e p-2'>{formatter.format(prod.product_net_total_with_vat)} </div>}
            </div>
        
    ));


    return (
        <div className='static'>
            <div className='text-xs py-3' >
            { customerName && <div  className='flex justify-between text-xs border rounded    mt-3 '>
                <div className=' border-e flex-1  items-center justify-between gap-3 p-2  '> 
                <div className='flex flex-col text-center'>
                    <span> اسم العميل </span>
                    <span> CUSTOMER NAME</span>
                    <span className='font-bold'>{customer?.fullname[0]?.text || customer?.fullname[1]?.text }</span>
                </div>
                </div>
                <div className=' border-e flex-1  items-center justify-between gap-3 p-2 '> 
                    <div className='flex flex-col text-center'>
                        <span> الرقم الضريبي للعميل</span>
                        <span> CUSTOMER VAT NO.</span>
                    <span className='font-bold'>{customer?.customerVatNo}</span>
                    </div>
                </div>
                
                <div className=' flex-1  items-center justify-between gap-3 p-2 '> 
                    <div className='flex flex-col text-center'>
                        <span> عنوان العميل</span>
                        <span>CUSTOMER ADDRESS </span>
                    <span className='font-bold'>{customer?.customerAddresses[0]?.desc}</span>
                    </div>
                </div>
            </div>}
                {/* <div className='flex justify-between text-xs px-1 pb-3 pt-1'>
                    {customerName && <div>{t('invoice.Customer')}: {customerName}</div>}
                    {salesmanName && <div>{t('invoice.Salesman')}: {salesmanName}</div>}
                </div> */}
                <div  className='flex mt-5  justify-between items-center  bg-slate-100 text-xs font-bold border rounded-t'>
                    <div className='flex-1 text-center border-e flex flex-col py-2'>
                        <span>اسم المنتج - الخدمة</span>
                        <span>PRODUCT / SERVICE NAME</span>
                    </div>
                    <div className='w-12 text-center border-e flex flex-col py-2'>
                        <span className=' text-center'>الكمية</span>
                        <span>QTY</span>
                    </div>
                    {
                    SETTING_INV_UOM_SHOW === 'true' && 
                        <div className='w-12 text-center border-e flex flex-col  py-2'>
                            <span>الوحدة</span>
                            <span>UNIT</span>
                        </div>
                    }
                    {
                    SETTING_INV_QTY_DAYS === 'true' && 
                        <div className='w-12 text-center border-e flex flex-col  py-2'>
                            <span> الايام </span>
                            <span>DAYS</span>
                        </div>
                    }
                    {
                    !isDelivery && 
                        <div className='w-20 text-center border-e flex flex-col py-2 '>
                            <span>سعر</span>
                            <span>RATE </span>
                        </div>
                    }
                    {!isDelivery && <div className='w-24 text-center border-e flex flex-col py-2'>
                        <span>مجموع</span>
                        <span>TOTAL</span>
                        </div>}
                    {!isDelivery && <div className='w-16 text-center border-e flex flex-col py-2'>
                        <span>الخصم</span>
                        <span>DISCOUNT</span>
                        </div>}
                    {!isDelivery && <div className='w-24 text-center  flex flex-col py-2'>
                        <span>نهائي</span>
                        <span>FINAL</span>
                        </div>}
                    {/* {!isDelivery && <div className='w-24 text-center  flex flex-col'>
                        <span>الضريبة ({SETTING_INV_VAT_PERCENT}%)</span>
                        <span>VAT ({SETTING_INV_VAT_PERCENT}%)</span>
                        </div>} */}
                    {!isDelivery && SETTING_INV_SHOW_ITEM_T_A_VAT === "true" && <div className='w-24 text-center  flex flex-col py-2'>
                        <span>إج. بعد الضريبة</span>
                        <span>T.A VAT</span>
                        </div>}
                </div>
                <div className=' '>{invProductsList}</div>
            </div>
            
        </div>
    )
}

export default InvoiceBody