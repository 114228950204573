import { useState } from 'react';
import OsViewWeights from '../../components/OsNewWeight/OsViewWeights';
import OsCreateWeight from '../../components/OsNewWeight/OsCreateWeight';
import OsEditWeight from '../../components/OsNewWeight/OsEditWeight';
import OsUnitDetails from '../../components/OsNewWeight/OsUnitDetails';
import OSviewAllListsPrices from '../../components/OsLIstsOfPrices/OSviewAllListsPrices';
import OsCreateListPrice from '../../components/OsLIstsOfPrices/OsCreateListPrice';
import CustomTable from '../../components/OsLIstsOfPrices/OsCustomTable';
import OsEditListName from '../../components/OsLIstsOfPrices/OsEditListName';

const OSListsPrices = () => {
    const [mode, setMode] = useState('List');
    const [idSelected, setIdSelected] = useState();

    return (
        <>
            <div className="p-3">
                {mode === 'List' && <OSviewAllListsPrices idSelected={idSelected} setIdSelected={setIdSelected} setMode={setMode} />}
                {mode === 'AddList' && <OsCreateListPrice setMode={setMode} />}
                {mode === 'DetailsLists' && idSelected && <CustomTable id={idSelected} setMode={setMode} />}
                {mode === 'EditList' && idSelected && <OsEditListName id={idSelected} setMode={setMode} />}
            </div>
        </>
    );
}

export default OSListsPrices;