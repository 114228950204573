import React, { useEffect, useState } from 'react'
import {BiSearch} from 'react-icons/bi'
import { MwInputText, MwSpinnerButton } from './ui';
import axios from 'axios';
import { useCurrent } from '../hooks';
import { Link, useParams } from 'react-router-dom';

const SearchCustomer = ({className,onChangeSearch=()=>{},isOpen=true}) => {
    const {currentTenantId,currentCompanyId,currentBranchId,currentLangId} = useCurrent();
    const token = localStorage.getItem('token');

    const params = useParams();
    const [term,setTerm] = useState();
    const [dataResult,setDataResult] = useState([]);
    const [show,setShow] = useState(false);
    const [loading,setLoading] = useState(false);
    const onChangeHandler = (e) => {
        setTerm(e.target.value);
    };

    const getResult = async () => {
        isOpen && setShow(true);
        setLoading(true);
        const response = await axios.get(`${process.env.REACT_APP_Auth_API_BASE_URL}/customers/name/${currentTenantId}/${currentCompanyId}/${currentBranchId}/${term}`,{
            headers:{
                'Authorization': `Bearer ${token}` 
            }
        });
        const result = response?.data?.data ;
        setLoading(false);
        console.log('dataResult',result);
        setDataResult(result)
        onChangeSearch(result)

    }

    useEffect(() => {
        if(!term){
            setShow(false)
        }
        const timeout = setTimeout(() => {
            if(term){
                getResult();
            }else{
                onChangeSearch([])
            }
        }, 1000);
        
        return () =>{
            clearTimeout(timeout);
        }
    },[term])

    return (
       
            <div className=' flex-1'>
                <div onClick={() => term && setShow(!show)} className={` relative ${show && 'border-t border-s border-e rounded-lg'} `}>
                    <BiSearch className='absolute top-2 start-3' size={16}/>
                    <MwInputText 
                        classNameInput={`bg-slate-50 rounded-lg ${className}`}
                        placeholder = 'أبحث عن عميل'
                        value = {term}
                        onChange = {onChangeHandler}
                        
                        />
                </div>
                {  show &&
                    <div className={` absolute z-50  top-9 w-3/6 overflow-y-auto h-48 border-b border-s border-e  bg-slate-50  rounded-b-lg opacity-95 ${'block'}`}>
                        { !loading ? 
                            dataResult.length > 0 ? 
                            <ul>
                            <li className='flex justify-between p-2 text-xs border-b'>
                                <div className='w-24 px-1 text-center'>رقم العميل</div>
                                <div className='flex-1'>اسم العميل</div>
                                {/* <div className='w-20 px-1  text-center'>رقم الجوال</div> */}
                            </li>
                            {dataResult.map((result) => (
                                <Link target='_blank' to={`/${params.tenant}/customers/show/${result?._id}/${result?.companyId}/${result?.branchId}`} key={result._id}>
                                    <li className=' flex justify-between p-2 text-xs border-b hover:bg-indigo-50 hover:font-bold'>
                                        <div className='w-24 px-1  text-center'>{result.customerNo}</div>
                                        <div className='flex-1'>{result?.fullname.filter(item => item.lang === currentLangId)[0]?.text || result.fullname[0]?.text || result?.fullname[1]?.text  }</div>
                                        {/* <div className='w-20 px-1  text-center'>{result.mobileNo} </div> */}
                                    </li>
                                </Link>
                            ))}
                        </ul> : <div className='text-center p-5'> لا يوجد نتائج </div> : <MwSpinnerButton withLabel={false} isFullCenter={true} /> }
                    </div> 
                }
                
            </div>
       
    )
}

export default SearchCustomer