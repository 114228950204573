import {useState,useEffect} from 'react'
import { useCurrent,useFetch, usePut } from '../../../hooks';
import {langObjType,objLangTextHandler,LangArrayToObjKeyValue} from '../../../utils/global'
import { MwButton, MwSpinner } from '../../ui';
import BrandForm from './BrandForm';
import CardHeadeWithActions from '../../ui/CardHeadeWithActions';


const EditBrand = ({id,setMode}) => {
    const {currentLangList,currentTenantId,currentCompanyId,currentLangId} = useCurrent();
    const [formLang,setFormLang] = useState(currentLangId);
    const [errors,setErrors] = useState({});
    const [loadingPage,setLoadingPage] = useState(true);

    const {data:dataFetchBrands,loading:loadingFetchBrands} = useFetch(`${process.env.REACT_APP_PRODUCTS_API_SERVER_BASE_URL}/brands/show/${id}`);
    const {data:resultPut,loading:loadingPut,error:errorPost,putData} = usePut();

    // Start Form state //////
    const [isActive,setIsActive] = useState(true);
    const [isShowOnWebsite,setIsShowOnWebsite] = useState(false);
    const [brandName,setBrandName] = useState(langObjType());
    const [brandDesc,setBrandDesc] = useState(langObjType());
    const [brandMetaKeywords,setBrandMetaKeywords] = useState(langObjType());

    const formValidate = () => {
        let errorObj = {};
        setErrors('');
        if(!brandName[currentLangId]){
            errorObj = { ...errorObj, [`brandName.${currentLangId}`] : 'يجب ادخال اسم الماركة'};
            setFormLang(currentLangId)
        }

        if(Object.keys(errorObj).length !== 0 ){
            setErrors(errorObj)
            return true
        }
        return false;
    }

    const submitHandler = () => {
        
        const _data = {
            brandName: objLangTextHandler(brandName),
            brandDesc: objLangTextHandler(brandDesc),
            brandMetaKeywords: objLangTextHandler(brandMetaKeywords),
            isActive,
            isShowOnWebsite,
            tenantId:currentTenantId,
            companyId:currentCompanyId
        }
        
        if(formValidate()) return ;
        putData(`${process.env.REACT_APP_PRODUCTS_API_SERVER_BASE_URL}/brands/update/${id}`,_data);
    }

    useEffect(() =>{
        if(resultPut){
            setMode('List');
        }
    },[resultPut])

    useEffect(() =>{
        if(dataFetchBrands?.data){
            console.log("dataFetchBrands=>",dataFetchBrands);
            setBrandName(LangArrayToObjKeyValue(dataFetchBrands.data.brandName))
            setBrandDesc(LangArrayToObjKeyValue(dataFetchBrands.data.brandDesc))
            setBrandMetaKeywords(LangArrayToObjKeyValue(dataFetchBrands.data.brandMetaKeywords))
            setIsShowOnWebsite(dataFetchBrands.data.isShowOnWebsite)
            setIsActive(dataFetchBrands.data.isActive)
            setLoadingPage(false)
        }
    },[dataFetchBrands]);
    

    return (
        <>
            { dataFetchBrands && !loadingPage ? <div className='flex flex-col gap-5 px-3 text-slate-500 text-sm' >
                    <CardHeadeWithActions title={`تعديل ماركة`}>
                            <MwButton type="cancelBtn" inGroup={true} onClick = {()=>setMode('List')} >ادارة الماركات</MwButton>
                            <MwButton type="saveBtn" inGroup={true} onClick={submitHandler} >حفظ</MwButton>
                    </CardHeadeWithActions>
                    <BrandForm
                        currentLangList = {currentLangList}
                        formLang = {formLang}
                        setFormLang = {setFormLang}
                        errors = {errors}
                        brandName = {brandName}
                        setBrandName = {setBrandName}
                        brandDesc = {brandDesc}
                        setBrandDesc = {setBrandDesc}
                        brandMetaKeywords = {brandMetaKeywords}
                        setBrandMetaKeywords = {setBrandMetaKeywords}
                        isShowOnWebsite = {isShowOnWebsite}
                        setIsShowOnWebsite = {setIsShowOnWebsite}
                        isActive = {isActive}
                        setIsActive  = {setIsActive}
                />
            </div> : <MwSpinner/> }
        </>
    )

}

export default EditBrand