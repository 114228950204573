import React, { useEffect, useState, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux';
import {QRCodeSVG} from 'qrcode.react';

import axios from 'axios';
import {  useTranslation } from 'react-i18next';
import { FaPhoneSquareAlt } from "react-icons/fa";
import { FiMapPin } from "react-icons/fi";
import { MdOutlineMailOutline } from "react-icons/md";
import { SiSitecore } from "react-icons/si";

import {getCompanyById} from '../../utils/tenantManager.js'
import {arrayNameByLang,printableDiv} from '../../utils/global.js'
import { useFetch,useQrInvoice,useLogs, useCurrent, useInvoice } from '../../hooks';
import { ConfirmDelete, SideModal, MwSpinner } from '../ui';
import InvoiceHeader from './show/InvoiceHeader.jsx';
import InvoiceBody from './show/InvoiceBody.jsx';
import InvoiceFooter from './show/InvoiceFooter.jsx';
import InvoiceNavbar from './show/InvoiceNavbar.jsx';
import PayInvoice from '../payment/PayInvoice.jsx';
import InvPaymentTransactions from './show/InvPaymentTransactions.jsx';
import InvSm from './print/InvSm.jsx';


const ShowInvoice = ({isPrint=false,isDelivery=false}) => {
    const divPrintRef = useRef();
    const divPrintRefSmall = useRef();
    const [t] = useTranslation('global')
    const {currentTenantId,currentCompanyId,currentBranchId,currentLangId} = useCurrent();
    const params = useParams();
    const navigate = useNavigate();
    const {formatter} = useInvoice();
    const [invoiceTypeSelected,setInvoiceTypeSelected] = useState({label:"فاتورة ضريبية",value:"standard"})
    const [showpaymentModal,setShowPaymentModal] = useState(false) ;
    const [showConfirmModal,setShowConfirmModal] = useState(false);
    const [invPaymentTransactions,setInvPaymentTransactions] = useState([])
    const [invPaymentTransactionsTotal,setInvPaymentTransactionsTotal] = useState(0)
    const lang = useSelector(state => state.lang);
    const invoiceSettings = useSelector(state => state.settings).invoiceSettings;
    const company = getCompanyById(params.companyId);
    const companyName =  arrayNameByLang(company?.name,lang.value)?.text;
    const companyDesc =  arrayNameByLang(company?.desc,lang.value)?.text;
    const companyAddress =  arrayNameByLang(company?.mainAddress,lang.value)?.text;
    const companyPhone =  company?.mainPhone;
    const companyEmail =  company?.mainEmail;
    const companyWebsite =  company?.websiteUrl;
    const vatNo = company.vatNumber;
    const commercialRegisteration = company?.commercialRegisteration;
    // const invTerms = invoiceSettings.INV_TERMS_PRINT
    const SETTINGTerms = invoiceSettings.INV_TERMS_PRINT
    const SETTING_SHOW_INVOICE_PRODUCT_DESCRIPTION = invoiceSettings?.SHOW_INVOICE_PRODUCT_DESCRIPTION || 'false';
    const SETTING_INV_WITH_HEADER_FOOTER = invoiceSettings?.INV_WITH_HEADER_FOOTER || 'false';
    const SETTING_INV_QTY_DAYS = invoiceSettings?.INV_QTY_DAYS || 'false';
    const SETTING_SHOW_TERMS_ON_PRINT = invoiceSettings.SHOW_TERMS_ON_PRINT
    const SETTING_INV_SHOW_ITEM_T_A_VAT = invoiceSettings.INV_SHOW_ITEM_T_A_VAT
    const SETTING_INV_FONT_COLOR = invoiceSettings.INV_FONT_COLOR
    const SETTING_INV_UOM_SHOW = invoiceSettings.INV_UOM_SHOW
    const SETTING_INV_TYPE_PRINT_DEFAULT = invoiceSettings.INV_TYPE_PRINT_DEFAULT
    const INV_CURRENCY = invoiceSettings?.INV_CURRENCY
    const SETTING_INV_CURRENCY = INV_CURRENCY || 'SAR'
    const SETTING_TYPE_INV_PRINT = invoiceSettings.TYPE_INV_PRINT; 

    const {resultLogsPost,loadingLogsPost,errorLogsPost,postDataHandler} = useLogs()

    const {data:invData,loading:invLoading,error,refreshHandler} = useFetch(`${process.env.REACT_APP_INV_API_SERVER_BASE_URL}/invoices/show/${params.id}`)
    const {data:customerData,fetchData:fetchCustomer,} = useFetch(`${process.env.REACT_APP_Auth_API_BASE_URL}/customers/show/${currentTenantId}/${currentCompanyId}/${currentBranchId}/${invData?.customer_id}`,false);

    const {result:resultQr,loading:loadingQR,error:errorQr} = useQrInvoice({
        sellerName : companyName,
        vatRegistration : vatNo,
        timestamp : invData?.inv_date,
        totalAmount : invData?.net_amount,
        vatTotalAmount : invData?.vat_amount
    });
    

    const getInvPaymentTransactions = async () => {
        const response = await axios.get(`${process.env.REACT_APP_INV_API_SERVER_BASE_URL}/payment-transactions/invoice/${invData?.id}`);
        setInvPaymentTransactions(response.data);
        const total = response.data.map(item => {
            return item.amount;
        })
        //console.log('total',total);
        setInvPaymentTransactionsTotal(total);
    }

    const setLogsHandler =  (itemEn='delete',itemAr='حذف') => {
        const _data = {
            moduleName: "SALES",
            resourceName: "INVOICES",
            eventName:"DELETE",
            logContentEn: `${itemEn} invoice No: ${invData.inv_no}`,
            logContentAr: `${itemAr} فاتورة برقم : ${invData.inv_no}`,
        }
        postDataHandler(_data);
    }

    useEffect(()=>{
        if(resultLogsPost){
            
        }
    },[resultLogsPost]);
    
    useEffect(() => {
        if(invData){
            console.log('invData',invData);

            invData?.customer_id && fetchCustomer();
            getInvPaymentTransactions();
            if(invoiceSettings?.INV_PAYMENT_AFTER_CREATE === 'true' && !invData?.is_paid){
                setShowPaymentModal(true)
            }                 
        }
    },[invData]);

    useEffect(() => {
        if(customerData){
            console.log('customerData',customerData);
        }
    },[customerData]);
    
    useEffect(() => {
        if(!loadingQR && isPrint){
            printHandler();
        }
    },[resultQr]);

    useEffect(() => {
        if(SETTING_INV_TYPE_PRINT_DEFAULT === "standard"){
            setInvoiceTypeSelected({label:"فاتورة ضريبية",value:"standard"})
        }else if(SETTING_INV_TYPE_PRINT_DEFAULT === "simplified"){
            setInvoiceTypeSelected({label:"فاتورة ضريبية مبسطة",value:"simplified"})
        }
    },[]);

    const deleteConfirm = async() => {
        const urlDeleteReq = `${process.env.REACT_APP_INV_API_SERVER_BASE_URL}/invoices/delete/${params.id}`;
        const res = await axios.delete(urlDeleteReq);
        if(res.data){
            setLogsHandler();
            navigate(`/${params.tenant}/invoices`);
        }
       // console.log(`Error deleting`);
    }

    const deleteHandler = () => {
        setShowConfirmModal(true);
    }
    const printHandler = () => {
        //console.log(`Successfully printed`)
        setLogsHandler('print','طباعة');
     SETTING_TYPE_INV_PRINT === 'lg' ? 
        printableDiv(divPrintRef,lang?.value) : printableDiv(divPrintRefSmall,lang?.value)
    };

    const onSubmitPay = () =>{
        //console.log('onSubmitPay');
        refreshHandler();
    }



    return (
        <>
            { !invLoading && !loadingQR && !errorQr ? 
            <div>
                <ConfirmDelete
                    title={`هل انت متاكد من حذف الفاتورة`}
                    msg={`هل انت متاكد من حذف الفاتورة`}
                    onShow={showConfirmModal}
                    setOnShow={setShowConfirmModal}
                    onDeleteHandler={deleteConfirm}
                />
                <SideModal 
                    title={t('invoice.PaymentInvoice')}
                    onShow={showpaymentModal} 
                    setOnShow={setShowPaymentModal}>
                    {showpaymentModal ? <PayInvoice
                        netAmount={(invData?.net_amount - invPaymentTransactionsTotal) || 0}
                        invId={invData?.id}
                        invNo={invData?.inv_no}
                        onSubmitPay={onSubmitPay}
                    />:<div></div>}
                </SideModal>
                <InvoiceNavbar
                    invoiceTypeSelected = {invoiceTypeSelected}
                    setInvoiceTypeSelected = {setInvoiceTypeSelected}
                    handlePrint = {printHandler}
                    deleteHandler = {deleteHandler}
                    isPaid = {invData?.is_paid}
                    isReturned = {invData?.is_returned}
                    countReturned = {invData?.count_returned}
                    fullReturned = {invData?.count_returned === invData?.inv_details?.length }
                    showpaymentModal = {showpaymentModal}
                    invId={invData?.id}
                    setShowPaymentModal = {setShowPaymentModal}
                />
                <div className='bg-slate-50 px-2  rounded-lg'>
                    <div id='printableDiv'  ref={divPrintRef} className='px-5   text-sm'>
                        <table className='w-full  '>
                            <thead><tr><td>
                                <div class="header-space">&nbsp;</div>
                            </td></tr></thead>
                            <tbody><tr><td>
                                <div class="  ">
                        
                                    <InvoiceHeader
                                        invoiceTypeSelected = {invoiceTypeSelected}
                                        logo = {company.logo} 
                                        companyName={companyName}
                                        companyCR = {company?.commercialRegisteration}
                                        companyDesc={companyDesc}
                                        vatNo = {vatNo}
                                        inv_no = {invData.inv_no}
                                        inv_date = {invData.inv_date}
                                        inv_date_due = {invData.inv_date_due}
                                        companyAddress = {companyAddress}
                                        qrText = {resultQr?.code}
                                        invoiceSettings = {invoiceSettings}
                                    />
                                    <InvoiceBody
                                        customerName = {invData.customer_name}
                                        customer = {customerData?.data}
                                        salesmanName = {invData.salesman_name}
                                        invProducts = {invData.inv_details}
                                        isDelivery = {isDelivery}
                                        SETTING_INV_VAT_PERCENT = {invoiceSettings.INV_VAT_PERCENT}
                                        formatter = {formatter}
                                        SETTING_INV_CURRENCY = {SETTING_INV_CURRENCY}
                                        SETTING_INV_QTY_DAYS = {SETTING_INV_QTY_DAYS}
                                        SETTING_INV_UOM_SHOW = {SETTING_INV_UOM_SHOW}
                                        SETTING_SHOW_INVOICE_PRODUCT_DESCRIPTION = {SETTING_SHOW_INVOICE_PRODUCT_DESCRIPTION}
                                        SETTING_INV_SHOW_ITEM_T_A_VAT = {SETTING_INV_SHOW_ITEM_T_A_VAT}
                                    />
                                    <InvoiceFooter
                                        totalAmount = {invData.total_amount}
                                        vatAmount = {invData.vat_amount}
                                        discountAmount = {invData.discount_amount}
                                        netAmount = {invData.net_amount}
                                        SETTINGTerms = {SETTINGTerms}
                                        // invTerms = {invTerms}
                                        SETTING_PRICE_INCLUDE_VAT = {invoiceSettings?.PRICE_INCLUDE_VAT}
                                        SETTING_INV_VAT_PERCENT = {invoiceSettings?.INV_VAT_PERCENT}
                                        SETTING_INV_PRINT_TERMS_SEPRATE_PAGE = {invoiceSettings?.INV_PRINT_TERMS_SEPRATE_PAGE}
                                        SETTING_SHOW_TERMS_ON_PRINT = {SETTING_SHOW_TERMS_ON_PRINT}
                                        SETTING_INV_CURRENCY = {SETTING_INV_CURRENCY}
                                        isDelivery={isDelivery}
                                        currentLang = {lang}
                                        formatter = {formatter}
                                        invNotes = {invData.notes}
                                        invTerms = {invData.terms}
                                    />
                            </div>
                        </td></tr></tbody>
                        <tfoot><tr><td>
                            <div class="footer-space">&nbsp;</div>
                        </td></tr></tfoot>
                        </table>
                        <div className='header w-full '>
                            <div className=' pb-5  flex justify-between   '>
                                <div className=' max-w-52 content-center   gap-1 '>
                                    {invoiceSettings?.SHOW_LOGO_ON_PRINT === 'true' && <div><img src={company.logo} className='w-full' /></div> }
                                    
                                </div>
                                <div className='w-96 px-5  content-center  ' dir='rtl' >
                                        <div className=' p-2 font-bold py-2 text-sm text-center' >{companyName}</div>
                                        <div className='flex flex-col  text-xs text-gray-500'>
                                            <div className=' p-2 flex items-center justify-between gap-3'>
                                                <span>   سجل تجاري:</span>
                                                <span className='font-bold'>{commercialRegisteration}</span>
                                                <span>:.C.R </span>
                                            </div>
                                            <div className=' p-2 flex items-center justify-between gap-3'>
                                                <span>  الرقم الضريبي:</span>
                                                <span className='font-bold'>{vatNo}</span>
                                                <span>:VAT NO</span>
                                            </div>
                                            { companyAddress && <div className=' p-2 flex items-center justify-between gap-3'>
                                                <span>   العنوان:</span>
                                                <span className='font-bold  text-center'>{companyAddress}</span>
                                                <span>:Address</span>
                                            </div>}
                                        </div>
                                </div>
                                <div className='pe-3 flex items-center justify-center'>
                                    <QRCodeSVG size={120}  value={resultQr?.code}  />
                                </div>
                            </div>
                            <hr className='py-2' style={{  borderColor:SETTING_INV_FONT_COLOR || '#000000'}} />
                        </div>
                        <div className={`footer w-full   pt-3  text-xs`} >
                        <hr className='py-2'  style={{  borderColor:SETTING_INV_FONT_COLOR || '#000000'}} />
                        <div className='w-full   px-5 pt-2 flex gap-5 justify-between'>
                            <div className='text-center  flex gap-1  items-center' ><span style={{color:SETTING_INV_FONT_COLOR}}><FiMapPin  /></span> {companyAddress}</div>
                            <div className='flex gap-1 items-center'><span style={{color:SETTING_INV_FONT_COLOR}}> <FaPhoneSquareAlt /> </span> <span className='pt-1'>{companyPhone}</span>  </div>
                            <div>
                                <div className='flex gap-1'><span style={{color:SETTING_INV_FONT_COLOR}}> <SiSitecore /> </span> {companyWebsite}</div>
                                <div className='flex gap-1'><span style={{color:SETTING_INV_FONT_COLOR}}> <MdOutlineMailOutline /> </span>{companyEmail}</div>
                            </div>
                        </div>
                        </div>
                    </div>  
                    <div className='flex hidden'>
                        <div id='printableDiv'  ref={divPrintRefSmall} className=' w-64 '> 
                            <InvSm
                                logo = {company.logo} 
                                companyName={companyName}
                                companyCR = {company?.commercialRegisteration}
                                companyDesc={companyDesc}
                                vatNo = {vatNo}
                                inv_no = {invData.inv_no}
                                inv_date = {invData.inv_date}
                                inv_date_due = {invData.inv_date_due}
                                companyAddress = {companyAddress}
                                qrText = {resultQr?.code}
                                invoiceSettings = {invoiceSettings}
                                customerName = {invData.customer_name}
                                customer = {customerData?.data}
                                salesmanName = {invData.salesman_name}
                                invProducts = {invData.inv_details}
                                isDelivery = {isDelivery}
                                formatter = {formatter}

                                totalAmount = {invData.total_amount}
                                vatAmount = {invData.vat_amount}
                                discountAmount = {invData.discount_amount}
                                netAmount = {invData.net_amount}
                                // invTerms = {invTerms}
                                SETTING_PRICE_INCLUDE_VAT = {invoiceSettings?.PRICE_INCLUDE_VAT}
                                SETTING_INV_VAT_PERCENT = {invoiceSettings?.INV_VAT_PERCENT}
                                SETTING_SHOW_TERMS_ON_PRINT = {SETTING_SHOW_TERMS_ON_PRINT}
                                SETTING_INV_CURRENCY = {SETTING_INV_CURRENCY}
                                currentLang = {lang}

                                invPaymentTransactions = {invPaymentTransactions}
                                currentLangId = {currentLangId}
                                />
                        </div> 
                    </div>
                

                </div>
                {invPaymentTransactions.length > 0 && <div className='px-3 py-3 my-5 bg-slate-50 rounded-lg'>
                    <InvPaymentTransactions 
                        SETTING_INV_CURRENCY = {SETTING_INV_CURRENCY} 
                        data = {invPaymentTransactions} 
                        formatter = {formatter}
                        />
                </div>}
            </div> : 
            <MwSpinner/> 
            }
        </>
    )
}

export default ShowInvoice