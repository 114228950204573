import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import MwButton from "../ui/MwButton";
import { getUOMById } from "../../store/weightSlice";
import { MdOutlineCancel } from "react-icons/md";
import { IoAddCircleOutline } from "react-icons/io5";
import { FaRegSave } from "react-icons/fa";
import ToggleSwitch from "./OsToggleSwitch";
import DeleteConfirmationModal from "./OsDeleteConfirmationModal ";
import { useDelete, usePut } from "../../hooks";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTenant } from "../../hooks";
import { AiOutlineDelete } from "react-icons/ai";

const OsEditWeight = ({ id, setMode }) => {
    const dispatch = useDispatch();
    const { putData, data: putResponse, loading: putLoading, error: putError } = usePut();
    const { deleteItem, data, error:deleteError } = useDelete();
    const { tenantId } = useTenant();
    const { uomByIdData, loading, error } = useSelector((state) => state.weight);
    const [unitData, setUnitData] = useState({ name: "", short_name: "", uom_details: [] });
    const [templateName, setTemplateName] = useState("");
    const [isActive, setIsActive] = useState(false);
    const [isModified, setIsModified] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [selectedUnitIndex, setSelectedUnitIndex] = useState(null);

    useEffect(() => {
        if (data) {
            // مش هنعرض أي توست هنا، هنعتمد على handleDeleteConfirm
        }
    }, [data]);
    useEffect(() => {
        if (data) {
            if (data?.status === 1) {
                console.log('useeffect from success')

                toast.success("تم الحذف بنجاح!", {
                    style: {
                        backgroundColor: '#e5e7eb',
                        color: '#374151',
                        padding: '1rem',
                        borderRadius: '0.5rem',
                        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
                    }
                });
            } else  {
                console.log('useeffect from err')
                toast.error("فشل الحذف!", {
                    style: {
                        backgroundColor: '#e5e7eb',
                        color: '#374151',
                        padding: '1rem',
                        borderRadius: '0.5rem',
                        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
                    }
                });
            }
        }
    }, [data]);
    useEffect(() => {
        if (deleteError) {
            console.log('useeffect from deleteError');
            toast.error("حدث خطأ أثناء الحذف!", {
                style: {
                    backgroundColor: '#e5e7eb',
                    color: '#374151',
                    padding: '1rem',
                    borderRadius: '0.5rem',
                    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
                }
            });
        }
    }, [deleteError]);

    useEffect(() => {
        dispatch(getUOMById(id));
    }, [dispatch, id]);

    useEffect(() => {
        if (uomByIdData?.data) {
            const { uom_master } = uomByIdData.data;
            if (uom_master) { // تأكيد من   uom_master
                setUnitData({
                    name: uom_master.name || "",
                    short_name: uom_master.short_name || "",
                    uom_details: uom_master.uom_details || []
                });
                setTemplateName(uomByIdData.data.name || "");
                setIsActive(uomByIdData.data.is_active === true);
            }
        }
    }, [uomByIdData]);

    useEffect(() => {
        const isAnyFieldModified = checkIfModified();
        setIsModified(isAnyFieldModified);
    }, [templateName, unitData, isActive]);

    const handleChange = (field, value) => {
        setUnitData({ ...unitData, [field]: value });
    };

    const handleDetailChange = (index, field, value) => {
        const updatedDetails = unitData.uom_details.map((detail, i) =>
            i === index ? { ...detail, [field]: value } : detail
        );
        setUnitData({ ...unitData, uom_details: updatedDetails });
    };

    const addNewUnit = () => {
        const minId = unitData.uom_details.reduce((min, detail) => (detail.id < min ? detail.id : min), 0);
        const newId = minId - 1;
        setUnitData((prev) => ({
            ...prev,
            uom_details: [...prev.uom_details, { id: newId, name: "", short_name: "", rate: 0 }]
        }));
    };

    const validateFields = () => {
        if (!templateName || !unitData?.name || !unitData?.short_name) return false;
        if (!unitData.uom_details) return false; // تأكيد من   uom_details

        for (const detail of unitData.uom_details) {
            if (!detail?.name || !detail?.short_name || !detail?.rate) return false;
        }
        return true;
    };

    const checkIfModified = () => {
        if (!uomByIdData?.data) return false;
        const { uom_master } = uomByIdData.data;
        if (!uom_master) return false;  

        const isTemplateNameModified = templateName !== uomByIdData.data.name;
        const isUnitDataModified =
            unitData?.name !== uom_master?.name ||
            unitData?.short_name !== uom_master?.short_name ||
            JSON.stringify(unitData?.uom_details) !== JSON.stringify(uom_master?.uom_details || []);
        const isActiveModified = isActive !== (uomByIdData.data.is_active === true);
        return isTemplateNameModified || isUnitDataModified || isActiveModified;
    };

    const handleSave = async () => {
        if (!tenantId) {
            toast.error("لم يتم العثور على tenantId", {
                style: {
                    backgroundColor: '#e5e7eb',
                    color: '#374151',
                    padding: '1rem',
                    borderRadius: '0.5rem',
                    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
                }
            });
            return;
        }

        if (!validateFields()) {
            toast.error("جميع الحقول الأساسية مطلوبة!", {
                style: {
                    backgroundColor: '#e5e7eb',
                    color: '#374151',
                    padding: '1rem',
                    borderRadius: '0.5rem',
                    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
                }
            });
            return;
        }

        if (!uomByIdData?.data?.uom_master) {
            toast.error("بيانات الوحدة غير متوفرة!", {
                style: {
                    backgroundColor: '#e5e7eb',
                    color: '#374151',
                    padding: '1rem',
                    borderRadius: '0.5rem',
                    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
                }
            });
            return;
        }

        const updatedData = {
            template_id: parseInt(id),
            uom_master_id: uomByIdData.data.uom_master.id,
            template_name: templateName,
            template_is_active: isActive ? 1 : 0,
            uom_master_name: unitData.name,
            uom_master_short_name: unitData.short_name,
            uom_details: unitData.uom_details.map((detail) => ({
                uom_details_id: detail.id || null,
                uom_details_name: detail.name,
                uom_details_short_name: detail.short_name,
                uom_details_rate: detail.rate.toString(),
            })),
            tenant_id: tenantId,
        };

        try {
            await putData(`${process.env.REACT_APP_INV_API_SERVER_BASE_URL}/inventory/uom`, updatedData);
            console.log('updatedData', updatedData);
        } catch (error) {
            console.error("Error updating data:", error);
        }
    };

    useEffect(() => {
        if (putResponse?.status) {
            toast.success("تم التحديث بنجاح", {
                style: {
                    backgroundColor: '#e5e7eb',
                    color: '#374151',
                    padding: '1rem',
                    borderRadius: '0.5rem',
                    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
                }
            });
        } else if (putError) {
            toast.error("حدث خطأ أثناء التحديث", {
                style: {
                    backgroundColor: '#e5e7eb',
                    color: '#374151',
                    padding: '1rem',
                    borderRadius: '0.5rem',
                    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
                }
            });
        }
    }, [putResponse, putError]);

    const handleDeleteClick = (id) => {
        const detailToDelete = unitData.uom_details.find(detail => detail.id === id);
        if (!detailToDelete) return; // تأكد من وجود detailToDelete

        if (id < 0 || (!detailToDelete.name && !detailToDelete.short_name && !detailToDelete.rate)) {
            const updatedDetails = unitData.uom_details.filter(detail => detail.id !== id);
            setUnitData({ ...unitData, uom_details: updatedDetails });
        } else {
            setSelectedUnitIndex(id);
            setIsDeleteModalOpen(true);
        }
    };

    const handleDeleteConfirm = async () => {
        if (selectedUnitIndex !== null && selectedUnitIndex >= 0) {
            try {
                await deleteItem(`${process.env.REACT_APP_INV_API_SERVER_BASE_URL}/inventory/uom/details/${selectedUnitIndex}`);
                
                // تحديث الواجهة فقط بعد نجاح الحذف
                const updatedDetails = unitData.uom_details.filter(detail => detail.id !== selectedUnitIndex);
                setUnitData({ ...unitData, uom_details: updatedDetails });

            } catch (error) {
                toast.error("حدث خطأ أثناء محاولة الحذف!", {
                    style: {
                        backgroundColor: '#e5e7eb',
                        color: '#374151',
                        padding: '1rem',
                        borderRadius: '0.5rem',
                        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
                    }
                });
                console.error("Error deleting unit:", error);
            } finally {
                setIsDeleteModalOpen(false);
                setSelectedUnitIndex(null);
            }
        }
    };

    if (loading) return <p className="text-center">يتم تحميل البيانات...</p>;
    if (error) return <p className="text-center text-red-500">حدث خطأ: {error}</p>;

    const handleCancel = () => {
        setMode("List");
    };

    return (
        <div className="bg-gray-100 p-6 rounded-lg shadow-md">
            <ToastContainer
                toastClassName="bg-gray-100 text-gray-900"
                bodyClassName="bg-gray-100 text-gray-900"
                progressClassName="bg-gray-300"
            />
            <DeleteConfirmationModal
                isOpen={isDeleteModalOpen}
                onClose={() => setIsDeleteModalOpen(false)}
                onConfirm={handleDeleteConfirm}
            />
            <div className="w-full flex flex-row-reverse mb-6">
                <MwButton
                    actionType="button"
                    size="sm"
                    inGroup={true}
                    type="deleteBtn"
                    onClick={handleCancel}
                >
                    <AiOutlineDelete className="ml-1" />
                    إلغاء
                </MwButton>
                <MwButton
                    actionType="button"
                    size="sm"
                    inGroup={true}
                    type="original-right"
                    onClick={handleSave}
                    disabled={!isModified || !validateFields() || putLoading}
                >
                    {putLoading ? (
                        <span>جار التحديث...</span>
                    ) : (
                        <>
                            <FaRegSave />
                            تحديث
                        </>
                    )}
                </MwButton>
            </div>
            <label className="text-sm text-gray-500 mb-1">اسم القالب</label>
            <div className="items-center flex justify-between mb-4 gap-3">
                <div className="w-[50%]">
                    <input
                        type="text"
                        placeholder="مثال: الوزن"
                        value={templateName}
                        onChange={(e) => setTemplateName(e.target.value)}
                        className="w-full flex p-2 border border-gray-300 rounded-md"
                    />
                </div>
                <ToggleSwitch
                    id="isActive"
                    label="نشط"
                    checked={isActive}
                    onChange={(e) => setIsActive(e.target.checked)}
                />
            </div>

            <div className="flex gap-4 mb-4">
                <div className="w-full">
                    <label className="block text-sm text-gray-500 mb-1">اسم الوحدة الأساسية</label>
                    <input
                        type="text"
                        value={unitData.name}
                        onChange={(e) => handleChange("name", e.target.value)}
                        placeholder="اسم الوحدة الأساسية"
                        className="w-full p-2 border border-gray-300 rounded-md"
                    />
                </div>
                <div className="w-full">
                    <label className="block text-sm text-gray-500 mb-1">التمييز</label>
                    <input
                        type="text"
                        value={unitData.short_name}
                        onChange={(e) => handleChange("short_name", e.target.value)}
                        placeholder="التمييز"
                        className="w-full p-2 border border-gray-300 rounded-md"
                    />
                </div>
            </div>

            {unitData.uom_details.map((detail, index) => (
                <div key={index} className="border border-gray-300 bg-gray-200 p-4 mb-4 rounded-md shadow-sm">
                    <div className="flex gap-4 mb-4">
                        <div className="w-full">
                            <label className="block text-sm text-gray-500 mb-1">اسم الوحدة الأكبر</label>
                            <input
                                type="text"
                                value={detail.name}
                                onChange={(e) => handleDetailChange(index, "name", e.target.value)}
                                placeholder="اسم الوحدة الأكبر"
                                className="w-full p-2 border border-gray-300 rounded-md"
                            />
                        </div>
                        <div className="w-full">
                            <label className="block text-sm text-gray-500 mb-1">التمييز</label>
                            <input
                                type="text"
                                value={detail.short_name}
                                onChange={(e) => handleDetailChange(index, "short_name", e.target.value)}
                                placeholder="التمييز"
                                className="w-full p-2 border border-gray-300 rounded-md"
                            />
                        </div>
                        <div className="w-full">
                            <label className="block text-sm text-gray-500 mb-1">معامل التحويل</label>
                            <div className="flex items-center gap-2">
                                <input
                                    type="number"
                                    value={detail.rate}
                                    onChange={(e) => handleDetailChange(index, "rate", e.target.value)}
                                    placeholder="معامل التحويل"
                                    className="w-full p-2 border border-gray-300 rounded-md"
                                />
                                <button
                                    onClick={() => handleDeleteClick(detail.id)}
                                    className="text-red-500 text-2xl mx-2"
                                >
                                    <MdOutlineCancel />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            ))}

            <div className="text-center mt-6">
                <MwButton
                    actionType="button"
                    inGroup={true}
                    type="addBtn"
                    onClick={addNewUnit}
                >
                    <IoAddCircleOutline />
                    إضافة وحدة جديدة
                </MwButton>
            </div>
        </div>
    );
};

export default OsEditWeight;