import React, { useState, useEffect } from 'react';
import MwButton from '../ui/MwButton';
import { usePost } from '../../hooks';
import { useTenant } from '../../hooks';
import { MdOutlineCancel } from "react-icons/md";
import { FaRegSave } from "react-icons/fa";
import { IoAddCircleOutline } from "react-icons/io5";
import ToggleSwitch from './OsToggleSwitch';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AiOutlineDelete } from 'react-icons/ai';

const OsCreateWeight = ({ setMode }) => {
    const { postData, data, loading, error } = usePost(true);
    const { tenantId } = useTenant();
    const [units, setUnits] = useState([{ id: 1, baseUnitName: "", label: "", name: "", factor: "", equalsTo: "" }]);
    const [templateName1, setTemplateName1] = useState("");
    const [templateName, setTemplateName] = useState("");
    const [uomMasterShortName, setUomMasterShortName] = useState("");
    const [isActive, setIsActive] = useState(false);
    const [errorMessages, setErrorMessages] = useState({});

    //  useEffect لمراقبة data و error
    useEffect(() => {
        if (data?.status === 1) {
            toast.success("تم الحفظ بنجاح", {
                style: {
                    backgroundColor: '#e5e7eb',
                    color: '#374151',
                    padding: '1rem',
                    borderRadius: '0.5rem',
                    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
                }
            });
    
            // تفريغ الحقول بعد الإضافة بنجاح
            setTemplateName1("");
            setTemplateName("");
            setUomMasterShortName("");
            setIsActive(false);
            setUnits([{ id: 1, baseUnitName: "", label: "", name: "", factor: "", equalsTo: "" }]);
            setErrorMessages({});
    
            // setMode('List'); // الرجوع إلى قائمة الوحدات بعد الحفظ
        } else if (error) {
            toast.error("حدث خطأ أثناء الحفظ", {
                style: {
                    backgroundColor: '#e5e7eb',
                    color: '#374151',
                    padding: '1rem',
                    borderRadius: '0.5rem',
                    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
                }
            });
        }
    }, [data, error, setMode]);

    const addUnit = () => {
        setUnits([...units, { id: units.length + 1, baseUnitName: "", label: "", name: "", factor: "", equalsTo: "" }]);
    };

    const removeUnit = (id) => {
        setUnits(units.filter((unit) => unit.id !== id));
    };

    const handleChange = (id, field, value) => {
        const updatedUnits = units.map((unit) => {
            if (unit.id === id) {
                const updatedUnit = { ...unit, [field]: value };
                if (field === "name") updatedUnit.equalsTo = `1 ${value}`;
                return updatedUnit;
            }
            return unit;
        });
        setUnits(updatedUnits);
    };

    const validateForm = () => {
        let errors = {};
        if (!templateName1.trim()) errors.templateName1 = "اسم القالب مطلوب";
        if (!templateName.trim()) errors.templateName = "اسم الوحدة الأساسية مطلوب";
        if (!uomMasterShortName.trim()) errors.uomMasterShortName = "التمييز مطلوب";
        units.forEach((unit, index) => {
            if (!unit.name.trim()) errors[`unitName-${unit.id}`] = `اسم الوحدة الأكبر مطلوب (وحدة ${index + 1})`;
            if (!unit.label.trim()) errors[`label-${unit.id}`] = `التمييز مطلوب (وحدة ${index + 1})`;
            if (!unit.factor.trim()) errors[`factor-${unit.id}`] = `معامل التحويل مطلوب (وحدة ${index + 1})`;
        });
        setErrorMessages(errors);
        return Object.keys(errors).length === 0;
    };

    const handleSave = async () => {
        if (!tenantId) {
            toast.error("لم يتم العثور على tenantId", {
                style: {
                    backgroundColor: '#e5e7eb',
                    color: '#374151',
                    padding: '1rem',
                    borderRadius: '0.5rem',
                    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
                }
            });
            return;
        }

        if (!validateForm()) {
            toast.error("يرجى ملء جميع الحقول المطلوبة", {
                style: {
                    backgroundColor: '#e5e7eb',
                    color: '#374151',
                    padding: '1rem',
                    borderRadius: '0.5rem',
                    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
                }
            });
            return;
        }

        const payload = {
            template_name: templateName1,
            template_is_active: isActive ? 1 : 0,
            uom_master_name: templateName,
            uom_master_short_name: uomMasterShortName,
            uom_details: units.map((unit) => ({
                uom_details_name: unit.name,
                uom_details_short_name: unit.label,
                uom_details_rate: unit.factor,
            })),
            tenant_id: tenantId,
        };

        try {
            await postData(`${process.env.REACT_APP_INV_API_SERVER_BASE_URL}/inventory/uom`, payload);
        } catch (error) {
            console.error("Error saving data:", error);
        }
    };
    const handleCancel = () => {
        setMode('List');
    };

    return (
        <div className="bg-gray-100 p-6 rounded-lg shadow-md">
            <ToastContainer
                toastClassName="bg-gray-100 text-gray-900"
                bodyClassName="bg-gray-100 text-gray-900"
                progressClassName="bg-gray-300"
            />
            <div className="w-full   flex flex-row-reverse mb-6  ">
            <MwButton 
        actionType="button" 
        size="sm" 
        inGroup={true} 
        type="deleteBtn" 
        onClick={handleCancel}
    >
        <AiOutlineDelete className="ml-1" />
        إلغاء
    </MwButton>
            <MwButton
                    actionType="button"
                       size="sm"
                    inGroup={true}
                    type="original-right"
                    onClick={handleSave}
                    disabled={loading} // تعطيل الزر أثناء التحميل
                >
                    {loading ? (
                        <span>جار الحفظ...</span> // عرض "جار الحفظ" أثناء التحميل
                    ) : (
                        <>
                            <FaRegSave />
                            <span>حفظ</span>
                        </>
                    )}
                </MwButton>
            </div>

            <label htmlFor="templateName" className="block text-sm text-gray-600 mb-1">اسم القالب</label>
            <div className="gap-4 flex justify-between mb-4">
                <div className="block w-[50%]">
                    <input id="templateName" type="text" value={templateName1} onChange={(e) => setTemplateName1(e.target.value)} className="w-full p-2 border border-gray-300 rounded-md" />
                    {errorMessages.templateName1 && <p className="text-red-500 text-sm">{errorMessages.templateName1}</p>}
                </div>
                <ToggleSwitch id="isActive" label="نشط" checked={isActive} onChange={(e) => setIsActive(e.target.checked)} />
            </div>

            <div className="flex gap-4 mb-4">
                <div className="flex-1">
                    <label htmlFor="baseUnitName" className="block text-sm text-gray-600 mb-1">اسم الوحدة الأساسية</label>
                    <input id="baseUnitName" type="text" value={templateName} onChange={(e) => setTemplateName(e.target.value)} className="w-full p-2 border border-gray-300 rounded-md" />
                    {errorMessages.templateName && <p className="text-red-500 text-sm">{errorMessages.templateName}</p>}
                </div>
                <div className="flex-1">
                    <label htmlFor="uomMasterShortName" className="block text-sm text-gray-600 mb-1">التمييز</label>
                    <input id="uomMasterShortName" type="text" value={uomMasterShortName} onChange={(e) => setUomMasterShortName(e.target.value)} className="w-full p-2 border border-gray-300 rounded-md" />
                    {errorMessages.uomMasterShortName && <p className="text-red-500 text-sm">{errorMessages.uomMasterShortName}</p>}
                </div>
            </div>

            {units.map((unit) => (
                <div key={unit.id} className="border border-gray-300 bg-gray-200 p-4 mb-4 rounded-md shadow-sm">
                    <div className="flex justify-between">
                        <div className="flex gap-4 flex-1">
                            <div className="flex-1">
                                <label htmlFor={`unitName-${unit.id}`} className="block text-sm text-gray-600 mb-1">اسم الوحدة الأكبر</label>
                                <input id={`unitName-${unit.id}`} type="text" value={unit.name} onChange={(e) => handleChange(unit.id, "name", e.target.value)} className="w-full p-2 border border-gray-300 rounded-md" />
                                {errorMessages[`unitName-${unit.id}`] && <p className="text-red-500 text-sm">{errorMessages[`unitName-${unit.id}`]}</p>}
                            </div>
                            <div className="flex-0">
                                <label htmlFor={`equalsTo-${unit.id}`} className="block text-sm text-gray-600 mb-1">يساوي</label>
                                <div id={`equalsTo-${unit.id}`} className="p-2 bg-gray-200">{unit.equalsTo}</div>
                            </div>
                            <div className="flex-1">
                                <label htmlFor={`label-${unit.id}`} className="block text-sm text-gray-600 mb-1">التمييز</label>
                                <input id={`label-${unit.id}`} type="text" value={unit.label} onChange={(e) => handleChange(unit.id, "label", e.target.value)} className="w-full p-2 border border-gray-300 rounded-md" />
                                {errorMessages[`label-${unit.id}`] && <p className="text-red-500 text-sm">{errorMessages[`label-${unit.id}`]}</p>}
                            </div>
                            <div className="flex-1">
                                <label htmlFor={`factor-${unit.id}`} className="block text-sm text-gray-600 mb-1">معامل التحويل</label>
                                <input id={`factor-${unit.id}`} type="number" value={unit.factor} onChange={(e) => handleChange(unit.id, "factor", e.target.value)} className="w-full p-2 border border-gray-300 rounded-md" />
                                {errorMessages[`factor-${unit.id}`] && <p className="text-red-500 text-sm">{errorMessages[`factor-${unit.id}`]}</p>}
                            </div>
                        </div>
                        <button onClick={() => removeUnit(unit.id)} className="text-red-500 text-2xl m-2 mt-7">
                            <MdOutlineCancel />
                        </button>
                    </div>
                </div>
            ))}

            <div className="mb-4">
                <MwButton actionType="button" inGroup={true} type="addBtn" onClick={addUnit}>
                    <IoAddCircleOutline />
                    <span>أضف وحدة</span>
                </MwButton>
            </div>
        </div>
    );
};

export default OsCreateWeight;;;