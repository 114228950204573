import React, { useEffect, useState } from 'react';
import { MdAddBox, MdClose, MdDelete, MdEdit } from 'react-icons/md';
import { MwSelectorProduct } from '../ui';
import MwButton from './../ui/MwButton';
import { AiOutlineArrowRight } from 'react-icons/ai';
import * as XLSX from 'xlsx';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux';
import { getListPrice } from '../../store/ListPriceSlice';
import { useCurrent, usePut, usePost,useDelete   } from '../../hooks';  
import DeleteConfirmationModal from '../OsNewWeight/OsDeleteConfirmationModal ';
import useDeleteWithBody from '../../hooks/useDeleteWithBody';
import { FaRegSave } from 'react-icons/fa';

const CustomTable = ({ id, setMode }) => {
  const { currentTenantId, currentCompanyId, currentBranchId } = useCurrent();
  const [rows, setRows] = useState([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false); // حالة لإدارة نافذة التأكيد
  const [itemToDelete, setItemToDelete] = useState(null); // العنصر المحدد للحذف
  const [showModal, setShowModal] = useState(false);
  const [newRow, setNewRow] = useState({
    id: null,
    item: '',
    price: '',
    product_id: 0, // إضافة product_id إلى newRow
  });
  const [errors, setErrors] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null); // العنصر المحدد للتعديل
  const [hasChanges, setHasChanges] = useState(false); // حالة لتتبع التغييرات
  const [selectedIds, setSelectedIds] = useState([]); // حالة لتخزين العناصر المحددة للحذف
  const dispatch = useDispatch();
  const { putData, data: putResponse, error: putError } = usePut(); // استخدام usePut
  const { data: postResponse,loading:postloading, error: postError, postData } = usePost(); // استخدام usePost
  console.log('putResponse', putResponse);
  const { ListData, loading, error } = useSelector((state) => state.ListPrice);
  const { deleteItem, data: deleteResponse, error: deleteError } = useDelete(); // استخدام useDelete
  const { deleteWithBody, data: deleteWithBodyResponse, loading: deleteWithBodyLoading, error: deleteWithBodyError } = useDeleteWithBody(); // استخدام useDeleteWithBody

  useEffect(() => {
    if (postResponse?.status == 1  ) {
      toast.success("تم الحفظ بنجاح", {
        style: {
          backgroundColor: '#e5e7eb',
          color: '#374151',
          padding: '1rem',
          borderRadius: '0.5rem',
          boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
        }
      });

      // setMode('List'); // الرجوع إلى قائمة الوحدات بعد الحفظ
    } else if (putError || postError || deleteError) {
      toast.error("حدث خطأ أثناء الحفظ", {
        style: {
          backgroundColor: '#e5e7eb',
          color: '#374151',
          padding: '1rem',
          borderRadius: '0.5rem',
          boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
        }
      });
    }
  }, [setMode, postResponse, postError, loading, deleteError]);

  useEffect(() => {
    if (deleteResponse?.status == 1) {
      toast.success("تم الحذف بنجاح", {
        style: {
          backgroundColor: '#e5e7eb',
          color: '#374151',
          padding: '1rem',
          borderRadius: '0.5rem',
          boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
        }
      });

      // setMode('List'); // الرجوع إلى قائمة الوحدات بعد الحفظ
    } else if (deleteError) {
      toast.error("حدث خطأ أثناء الحذف", {
        style: {
          backgroundColor: '#e5e7eb',
          color: '#374151',
          padding: '1rem',
          borderRadius: '0.5rem',
          boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
        }
      });
    }
  }, [deleteResponse, deleteError]);

  useEffect(() => {
    if (id) {
      dispatch(getListPrice(`${process.env.REACT_APP_INV_API_SERVER_BASE_URL}/inventory/list-price-items/${id}`));
    }
  }, [dispatch, id]);

  const units = Array.isArray(ListData) ? ListData : [];
  console.log("units", units);

  useEffect(() => {
    if (units.length > 0) {
      const newRows = units.map((unit, index) => ({
        id: unit.id, // استخدام id الفعلي من البيانات
        item: unit.product_name ? unit.product_name.trim() : '',
        price: unit.product_sale_price || '',
        list_price_id: unit.list_price_id, // إضافة list_price_id
        product_id: unit.product_id || 0, // إضافة product_id
      }));
      setRows(newRows);
    } else {
      setRows([]);
    }
  }, [units]);

  // عرض Spinner أثناء التحميل
  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  // عرض رسالة خطأ إذا حدث خطأ
  if (error) {
    return (
      <div className="text-center p-4">
        <p className="text-red-500">حدث خطأ أثناء جلب البيانات: {error}</p>
      </div>
    );
  }

  const addOrUpdateRow = () => {
    const newErrors = {};
    if (!newRow.item) newErrors.item = 'البند مطلوب';
    if (!newRow.price) newErrors.price = 'السعر مطلوب';
  
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
  
    if (isEditing) {
      const updatedRows = rows.map((row) =>
        row.id === newRow.id ? { ...row, item: newRow.item, price: newRow.price } : row
      );
      setRows(updatedRows);
    } else {
      // إضافة id مؤقت للعنصر الجديد
      const updatedRow = { id: `temp-${Date.now()}`, ...newRow, isNew: true }; // إضافة علامة isNew
      setRows([...rows, updatedRow]);
    }
  
    setNewRow({ id: null, item: '', price: '', product_id: 0 });
    setErrors({});
    setShowModal(false);
    setIsEditing(false);
    setHasChanges(true); // تم إجراء تغيير
  };

  const handleInputChange = (e, field) => {
    setNewRow({ ...newRow, [field]: e.target.value });
    setErrors({ ...errors, [field]: '' });
  };

  const handleProductSelect = (selectedProduct) => {
    if (selectedProduct && selectedProduct._id) {
      const productName =
        selectedProduct.productName[0]?.text || selectedProduct.productName[1]?.text || '';
      setNewRow({
        ...newRow,
        item: productName,
        product_id: selectedProduct._id, // تعيين _id كـ product_id
      });
    } else {
      setNewRow({ ...newRow, item: selectedProduct, product_id: 0 }); // إذا كانت القيمة نصًا، تعيين product_id إلى 0
    }
  };

  // فتح نافذة التأكيد عند الضغط على زر الحذف
  const handleDeleteClick = (id) => {
    setItemToDelete(id); // تعيين العنصر المحدد للحذف
    setDeleteModalOpen(true); // فتح نافذة التأكيد
  };

  // تنفيذ الحذف بعد التأكيد
  const handleConfirmDelete = async () => {
    if (selectedIds.length > 0) {
      // التحقق من وجود عناصر جديدة في القائمة المحددة
      const hasNewItems = selectedIds.some((id) => rows.find((row) => row.id === id && row.isNew));
      if (hasNewItems) {
        toast.error("لا يمكن حذف عناصر جديدة قبل حفظها!", {
          style: {
            backgroundColor: '#e5e7eb',
            color: '#374151',
            padding: '1rem',
            borderRadius: '0.5rem',
            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
          }
        });
        return;
      }
  
      await handleDeleteMultipleItems();
    } else if (itemToDelete) {
      // التحقق من أن العنصر المحدد ليس جديدًا
      const rowToDelete = rows.find((row) => row.id === itemToDelete);
      if (rowToDelete && rowToDelete.isNew) {
        toast.error("لا يمكن حذف عنصر جديد قبل حفظه!", {
          style: {
            backgroundColor: '#e5e7eb',
            color: '#374151',
            padding: '1rem',
            borderRadius: '0.5rem',
            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
          }
        });
        return;
      }
  
      try {
        await deleteItem(`${process.env.REACT_APP_INV_API_SERVER_BASE_URL}/inventory/list-price-items/${itemToDelete}`);
        toast.success("تم الحذف بنجاح", {
          style: {
            backgroundColor: '#e5e7eb',
            color: '#374151',
            padding: '1rem',
            borderRadius: '0.5rem',
            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
          }
        });
        dispatch(getListPrice(`${process.env.REACT_APP_INV_API_SERVER_BASE_URL}/inventory/list-price-items/${id}`));
      } catch (error) {
        toast.error("حدث خطأ أثناء الحذف", {
          style: {
            backgroundColor: '#e5e7eb',
            color: '#374151',
            padding: '1rem',
            borderRadius: '0.5rem',
            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
          }
        });
        console.error('Error deleting item:', error);
      }
    }
    setDeleteModalOpen(false);
    setItemToDelete(null);
  };
   const handleCancelDelete = () => {
    setDeleteModalOpen(false); // إغلاق نافذة  
    setItemToDelete(null); // إعادة تعيين العنصر المحدد للحذف
  };

  const openEditModal = (row) => {
    if (row.isNew) {
      toast.error("لا يمكن تعديل عنصر جديد قبل حفظه!", {
        style: {
          backgroundColor: '#e5e7eb',
          color: '#374151',
          padding: '1rem',
          borderRadius: '0.5rem',
          boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
        }
      });
      return;
    }
  
    setSelectedItem(row); // حفظ العنصر المحدد
    setNewRow({ id: row.id, item: row.item, price: row.price, product_id: row.product_id || 0 }); // تعيين البيانات الحالية
    setIsEditing(true);
    setShowModal(true);
  };

  const handleUpdate = async () => {
    if (!selectedItem) return;

    // التحقق من أن selectedItem يحتوي على list_price_id و id
    if (!selectedItem.list_price_id || !selectedItem.id) {
      toast.error("بيانات غير صحيحة للتحديث!", {
        style: {
          backgroundColor: '#e5e7eb',
          color: '#374151',
          padding: '1rem',
          borderRadius: '0.5rem',
          boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
        }
      });
      return;
    }

    const updatedData = {
      list_price_id: selectedItem.list_price_id, // استخدام list_price_id من العنصر المحدد
      product_id: newRow.product_id || 0, // استخدام _id من العنصر المحدد أو 0 إذا لم يكن موجودًا
      product_name: newRow.item, // استخدام القيمة الجديدة
      product_sale_price: newRow.price, // استخدام القيمة الجديدة
    };

    console.log("updatedData put", updatedData);

    try {
      await putData(`${process.env.REACT_APP_INV_API_SERVER_BASE_URL}/inventory/list-price-items/${selectedItem.id}`, updatedData);
      setShowModal(false); // إغلاق Modal بعد التحديث
      dispatch(getListPrice(`${process.env.REACT_APP_INV_API_SERVER_BASE_URL}/inventory/list-price-items/${id}`)); // إعادة جلب البيانات
      setHasChanges(false); // إعادة تعيين التغييرات بعد التحديث
    } catch (error) {
      toast.error("حدث خطأ أثناء التحديث!", {
        style: {
          backgroundColor: '#e5e7eb',
          color: '#374151',
          padding: '1rem',
          borderRadius: '0.5rem',
          boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
        }
      });
      console.error('Error updating item:', error);
    }
  };

  const handleSaveData = async () => {
    const payload = rows.map((row) => ({
      list_price_id: units[0]?.list_price_id,
      product_id: row.product_id,
      product_name: row.item,
      product_sale_price: row.price,
      tenant_id: currentTenantId,
      company_id: currentCompanyId,
      branch_id: currentBranchId,
    }));
    console.log("payload post", payload);
    try {
      await postData(`${process.env.REACT_APP_INV_API_SERVER_BASE_URL}/inventory/list-price-items`, payload);
      setHasChanges(false); // إعادة تعيين التغييرات بعد الحفظ
    } catch (error) {
      toast.error("حدث خطأ أثناء حفظ البيانات!", {
        style: {
          backgroundColor: '#e5e7eb',
          color: '#374151',
          padding: '1rem',
          borderRadius: '0.5rem',
          boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
        }
      });
      console.error('Error saving data:', error);
    }
  };

  const handleBack = () => {
    setMode('List');
  };

  const handleAddProduct = () => {
    return false;
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (!file) return;
  
    if (!file.name.endsWith('.xlsx') && !file.name.endsWith('.xls') && !file.name.endsWith('.csv')) {
      toast.error('برجاء تحميل ملف Excel أو CSV فقط.', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: { backgroundColor: '#e5e7eb', color: '#374151' },
      });
      return;
    }
  
    const reader = new FileReader();
    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result);
      let workbook;
  
      if (file.name.endsWith('.csv')) {
        const csvData = event.target.result;
        workbook = XLSX.read(csvData, { type: 'string' });
      } else {
        workbook = XLSX.read(data, { type: 'array' });
      }
  
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
  
      const newRows = jsonData.slice(1).map((row, index) => ({
        id: `temp-excel-${Date.now()}-${index}`, // إضافة id مؤقت
        item: row[0] || '',
        price: row[1] || '',
        product_id: 0, // تعيين product_id إلى 0 للعناصر المستوردة من Excel
        isNew: true, // إضافة علامة isNew
      }));
  
      setRows([...rows, ...newRows]);
      setHasChanges(true); // تم إجراء تغيير
    };
  
    if (file.name.endsWith('.csv')) {
      reader.readAsText(file);
    } else {
      reader.readAsArrayBuffer(file);
    }
  };

  const handleDeleteMultipleItems = async () => {
    if (selectedIds.length === 0) return;

    try {
      await deleteWithBody(`${process.env.REACT_APP_INV_API_SERVER_BASE_URL}/inventory/list-price-items`, {
        ids: selectedIds,
      });
      console.log("selectedIds", selectedIds)
      toast.success("تم الحذف بنجاح", {
        style: {
          backgroundColor: '#e5e7eb',
          color: '#374151',
          padding: '1rem',
          borderRadius: '0.5rem',
          boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
        }
      });
      dispatch(getListPrice(`${process.env.REACT_APP_INV_API_SERVER_BASE_URL}/inventory/list-price-items/${id}`));
      setSelectedIds([]); // إعادة تعيين العناصر المحددة بعد الحذف
    } catch (error) {
      toast.error("حدث خطأ أثناء الحذف", {
        style: {
          backgroundColor: '#e5e7eb',
          color: '#374151',
          padding: '1rem',
          borderRadius: '0.5rem',
          boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
        }
      });
      console.error('Error deleting items:', error);
    }
  };

  const handleCheckAll = (e) => {
    if (e.target.checked) {
      // تحديد كل العناصر ما عدا الجديدة
      const allIds = rows.filter((row) => !row.isNew).map((row) => row.id);
      setSelectedIds(allIds);
    } else {
      setSelectedIds([]);
    }
  };

  const handleCheckItem = (id) => {
    const row = rows.find((row) => row.id === id);
    if (row && row.isNew) {
      toast.error("لا يمكن تحديد عنصر جديد قبل حفظه!", {
        style: {
          backgroundColor: '#e5e7eb',
          color: '#374151',
          padding: '1rem',
          borderRadius: '0.5rem',
          boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
        }
      });
      return;
    }
  
    if (selectedIds.includes(id)) {
      setSelectedIds(selectedIds.filter((itemId) => itemId !== id));
    } else {
      setSelectedIds([...selectedIds, id]);
    }
  };

  return (
    <div className="p-4 bg-gray-100">
      <div className="my-5 flex justify-between items-center">
        <MwButton
          actionType="button"
          size="sm"
          inGroup={true}
          type="circular-back"
          onClick={handleBack}
        >
          <AiOutlineArrowRight className="ml-1" />
          عودة
        </MwButton>

        <MwButton
          onClick={handleSaveData}
          type='saveBtn'
          size="md"
          disabled={postloading|| !hasChanges}
        >
           {postloading ? (
                                  <span>جار التحديث...</span>
                              ) : (
                                  <>
                                      <FaRegSave />
                                      <span>تحديث</span>
                                  </>
                              )}
        </MwButton>
      </div>

      <div className="mt-4">
        <label className="bg-green-500 justify-center text-white font-bold p-2 rounded flex items-center cursor-pointer">
          <input
            type="file"
            accept=".xlsx, .xls, .csv"
            onChange={handleFileUpload}
            className="hidden"
          />
          EXCEL IMPORT
        </label>
      </div>

      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <table className="min-w-full bg-white text-gray-800 border-collapse">
        <thead>
          <tr>
          <th className="border p-3 w-[150px] text-center align-middle">
  <div className="flex flex-col items-center gap-2 justify-center">
    <p className="text-sm font-medium text-gray-700 mb-2">تحديد الكل</p>
    <label className="inline-flex items-center cursor-pointer">
      <input
        type="checkbox"
        onChange={handleCheckAll}
        checked={selectedIds.length === rows.length && rows.length > 0}
        className="
          appearance-none 
          w-6 h-6 
          border-2 border-gray-300 
          rounded-full 
          checked:bg-blue-500 
          checked:border-blue-500 
          transition-colors 
          duration-200 
          ease-in-out 
          cursor-pointer
          shadow-sm
          hover:shadow-md
          focus:outline-none
          focus:ring-2
          focus:ring-blue-500
          focus:ring-offset-2
        "
      />
    </label>
  </div>
</th>
            <th className="border p-2 text-center align-middle">البند</th>
            <th className="border p-2 text-center align-middle">الماركة</th>
            <th className="border p-2 text-center align-middle">التصنيف</th>
            <th className="border p-2 text-center align-middle">سعر المنتج</th>
            <th className="border p-2 text-center align-middle">إجراءات</th>
          </tr>
        </thead>

        <tbody>
  {rows.length > 0 ? (
    rows.map((row) => (
      <tr key={row.id} className={row.isNew ? "bg-yellow-100" : ""}>
        <td className="border p-2 text-center align-middle">
          <input
            type="checkbox"
            checked={selectedIds.includes(row.id)}
            onChange={() => handleCheckItem(row.id)}
            disabled={row.isNew} // تعطيل checkbox للعناصر الجديدة
          />
        </td>
        <td className="border p-2 text-center align-middle">{row.item}</td>
        <td className="border p-2 text-center align-middle">-</td>
        <td className="border p-2 text-center align-middle">-</td>
        <td className="border p-2 text-center align-middle">{row.price}</td>
        <td className="border p-2 text-center align-middle flex items-center justify-center gap-2">
          <button
            onClick={() => openEditModal(row)}
            className={`text-blue-500 hover:text-blue-700 ${
              row.isNew ? "opacity-50 cursor-not-allowed" : ""
            }`}
            disabled={row.isNew} // تعطيل زر التعديل للعناصر الجديدة
          >
            <MdEdit size={20} />
          </button>
          <button
            onClick={() => handleDeleteClick(row.id)}
            className={`text-red-500 hover:text-red-700 ${
              row.isNew ? "opacity-50 cursor-not-allowed" : ""
            }`}
            disabled={row.isNew} // تعطيل زر الحذف للعناصر الجديدة
          >
            <MdDelete size={20} />
          </button>
        </td>
      </tr>
    ))
  ) : (
    <tr>
      <td colSpan="6" className="text-center p-4">
        لا توجد بيانات متاحة
      </td>
    </tr>
  )}
</tbody>
      </table>

      {selectedIds.length > 0 && (
        <button
          onClick={() => setDeleteModalOpen(true)}
          className="mt-4 bg-red-500 text-white p-2 rounded flex items-center "
        >
          <MdDelete className="mr-2" /> حذف العناصر المحددة
        </button>
      )}

      <button
        onClick={() => {
          setNewRow({ id: null, item: '', price: '', product_id: 0 });
          setIsEditing(false);
          setShowModal(true);
        }}
        className="mt-4 bg-blue-500 text-white p-2 rounded flex items-center   "
      >
        <MdAddBox className="mr-2" /> إضافة
      </button>

      {/* نافذة التأكيد على الحذف */}
      <DeleteConfirmationModal
        isOpen={deleteModalOpen}
        onClose={handleCancelDelete}
        onConfirm={handleConfirmDelete}
        message={selectedIds.length > 1 ? "هل أنت متأكد من حذف العناصر المحددة؟" : "هل أنت متأكد من حذف هذا العنصر؟"}
      />

      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg w-11/12 max-w-[50%] relative">
            <button
              onClick={() => setShowModal(false)}
              className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
            >
              <MdClose size={24} />
            </button>

            <h3 className="text-lg font-bold mb-4">
              {isEditing ? 'تحديث عنصر' : 'إضافة عنصر جديد'}
            </h3>
            <div className="space-y-4">
              <div>
                <label className="block">البند:</label>
                <MwSelectorProduct
                  onChangeCallback={handleProductSelect}
                  withLabel={true}
                  value={newRow.item}
                  initalValue={newRow.item}
                  onCreateProduct={handleAddProduct}
                />
                {errors.item && <p className="text-red-500 text-sm">{errors.item}</p>}
              </div>
              <div>
                <label className="block">السعر:</label>
                <input
                  type="text"
                  placeholder="السعر"
                  value={newRow.price}
                  onChange={(e) => handleInputChange(e, 'price')}
                  className="w-full p-2 border rounded"
                />
                {errors.price && <p className="text-red-500 text-sm">{errors.price}</p>}
              </div>
            </div>

            <button
              onClick={isEditing ? handleUpdate : addOrUpdateRow}
              className="mt-4 bg-blue-500 text-white p-2 rounded w-full"
            >
              {isEditing ? 'تحديث' : 'إضافة'}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomTable;