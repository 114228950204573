import React from 'react'
import MwInputText from './MwInputText'

import {PiPlusSquareLight,PiMinusSquareLight} from 'react-icons/pi'

const InputQty = ({label,disabled=false,value,onChange,onIncress,onDecress,custClassName,width='24'}) => {
    return (
        <>
            { label && 
                <label className='text-xs px-1 text-slate-400  text-center' >{label}</label>}
            <div 
                className={`flex justify-center items-center  w-${width} rounded`}>
                <PiPlusSquareLight 
                    onClick={ !disabled ? onIncress : ()=>{}}
                    className={`  px-1 h-8 rounded-s-lg border ${ disabled ? 'bg-slate-200 text-slate-500' :'bg-white' } `} size={24}/>
                <MwInputText 
                    // label={`${  label}`}
                    disabled = {disabled}
                    value={value} 
                    onChange={onChange}
                    classNameInput = {`border ${custClassName}`}  
                    
                    />
                <PiMinusSquareLight
                    onClick={ !disabled ? onDecress : ()=>{}}
                    className={ `px-1 h-8 rounded-e-lg border ${ disabled ? 'bg-slate-200 text-slate-500' :'bg-white' } `}
                    size={24}/>
            </div>
        </>
    )
}

export default InputQty