import { useCurrent } from '../hooks';
import {LangArrayToObjKeyValue} from '../utils/global';

const CustomerSelectorItem = ({item,setSelectedItem,setOpen,setInputValue,inputValue}) => {
    const {currentLangId} = useCurrent();
    const name = LangArrayToObjKeyValue(item.fullname)?.[currentLangId] || item.fullname[0].text 
    return (
        <li 
            key={item._id}
            onClick={()=>{
                setSelectedItem({...item,label:name,value:item._id, lang:currentLangId})
                setOpen(false);
                setInputValue("")
            }}
            className={`px-3 py-1 text-xs text-slate-600 hover:bg-sky-600 hover:text-white ${name?.toLowerCase()?.includes(inputValue.toLowerCase()) ? 'block' : 'hidden'}`}
        >
            {name}
        </li>
    )
}

export default CustomerSelectorItem