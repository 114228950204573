import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getUOMData } from '../../store/weightSlice';
import { MwButton } from '../ui';
import { AiOutlineDelete } from "react-icons/ai";
import { MdOutlineEdit } from "react-icons/md";
import { CiViewList } from "react-icons/ci";
import { IoAddCircleOutline } from "react-icons/io5";
import { useDelete, useTenant } from '../../hooks';
import DeleteConfirmationModal from './OsDeleteConfirmationModal ';
import { toast,ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
 

const OsViewWeights = ({ idSelected, setIdSelected, setMode }) => {
    const { tenantId, loading: tenantLoading, error: tenantError } = useTenant();
    const navigate = useNavigate();
    const { tenant } = useParams();
    const dispatch = useDispatch();
    const { deleteItem } = useDelete();

    const { uomData, loading, error } = useSelector((state) => state.weight);
    const units = uomData ? uomData : [];
    const [menuVisible, setMenuVisible] = useState(null);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [selectedUnitId, setSelectedUnitId] = useState(null);

    useEffect(() => {
        if (tenantId) {
            dispatch(getUOMData(tenantId));
        }
    }, [dispatch, tenantId]);
console.log('view all data' , uomData)
    const handleEdit = (id) => {
        setIdSelected(id);
        setMode('Edit');
    };

    const handleDelete = (id) => {
      console.log('delete id',id)
        setSelectedUnitId(id);
        setDeleteModalOpen(true);
    };

    const handleConfirmDelete = () => {
        if (selectedUnitId && tenantId) {
            deleteItem(`${process.env.REACT_APP_INV_API_SERVER_BASE_URL}/inventory/uom/${selectedUnitId}`)
                .then(() => {
                     toast.success("تم الحذف بنجاح!", {
                        style: {
                            backgroundColor: '#e5e7eb',
                            color: '#374151',
                            padding: '1rem',
                            borderRadius: '0.5rem',
                            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
                        }
                    });
    
                    // إغلاق modal الحذف
                    setDeleteModalOpen(false);
    
                    // إعادة تعيين الحالة
                    setSelectedUnitId(null);
    
                    // تحديث البيانات
                    dispatch(getUOMData(tenantId));
                })
                .catch((error) => {
                    // إظهار رسالة خطأ
                    toast.error("حدث خطأ أثناء محاولة الحذف!", {
                        style: {
                            backgroundColor: '#e5e7eb',
                            color: '#374151',
                            padding: '1rem',
                            borderRadius: '0.5rem',
                            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
                        }
                    });
    
                    console.error('Error deleting unit:', error);
                });
        }
    };

    const handleView = (id) => {
        setIdSelected(id);
        setMode('Details');
    };

    const toggleMenu = (id) => {
        setMenuVisible((prev) => (prev === id ? null : id));
    };

    const AddUnit = () => {
        setMode('Add');
    };

    if (tenantLoading || loading) {
        return (
            <div className="flex justify-center items-center h-64">
                <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
            </div>
        );
    }

    if (tenantError || error) {
        return (
            <div className="bg-red-50 p-4 rounded-lg">
                <p className="text-red-600">حدث خطأ أثناء تحميل البيانات: {tenantError?.message || error?.message || 'خطأ غير معروف'}</p>
            </div>
        );
    }

    if (units.length === 0) {
        return (
            <div className="bg-gray-50 p-6 rounded-lg text-center flex flex-col items-center justify-center">
            <p className="text-gray-600 mb-4">لا توجد وحدات متاحة</p>
            <div>
                <MwButton inGroup={true} type="saveBtn" onClick={AddUnit}>
                    <IoAddCircleOutline />
                    <span>إضافة وحدة جديدة</span>
                </MwButton>
            </div>
        </div>
        );
    }

    return (
        <div className="bg-gray-100 p-6 rounded-lg shadow-md">
            <ToastContainer/>
            <div className='mb-3 flex justify-between items-center'>
                <h2 className="text-xl font-bold mb-4">قائمة قوالب الوحدات</h2>
                <MwButton inGroup={true} type="saveBtn" onClick={AddUnit}>
                    <IoAddCircleOutline />
                    <span> إضافة وحدة  </span>
                </MwButton>
            </div>
            <div className="min-w-[600px]">
                {units.map((unit) => (
                    <div key={unit.id} className="flex justify-between items-center p-4 border bg-gray-200 hover:bg-gray-300 transition-all duration-300 border-gray-300 rounded-md shadow-sm mb-4">
                        <div>
                            <p className="font-semibold">{unit?.name || 'اسم غير متوفر'}</p>
                            <p className="text-sm text-gray-500">{unit.uom_master?.name || 'تسمية غير متوفرة'}</p>
                        </div>
                        <div className="flex items-center gap-4 relative">
                            <div className={`h-4 w-4 rounded-full ${unit.is_active ? 'bg-green-500' : 'bg-red-500'}`} title={unit.is_active ? 'نشط' : 'غير نشط'}></div>
                            <button className="text-gray-500 hover:text-gray-700" onClick={() => toggleMenu(unit.id)}>⋮</button>
                            {menuVisible === unit.id && (
                                <div className="absolute left-1 top-7 bg-white shadow-lg rounded-md p-2 z-10 border border-gray-300" onClick={(e) => e.stopPropagation()}>
                                    <button onClick={() => handleView(unit.id)} className="flex items-center gap-1 text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                                        <CiViewList />
                                        عرض
                                    </button>
                                    <button onClick={() => handleEdit(unit.id)} className="flex gap-1 items-center text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                                        <MdOutlineEdit />
                                        تعديل
                                    </button>
                                    <button onClick={() => handleDelete(unit.id)} className="items-center gap-1 flex text-left px-4 py-2 text-sm text-red-500 hover:bg-gray-100">
                                        <AiOutlineDelete />
                                        حذف
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                ))}
            </div>

            <DeleteConfirmationModal isOpen={deleteModalOpen} onClose={() => setDeleteModalOpen(false)} onConfirm={handleConfirmDelete} />
        </div>
    );
};

export default OsViewWeights;;;