import React, { useState, useEffect } from 'react';
import MwButton from '../ui/MwButton';
import { useCurrent, usePost, usePut } from '../../hooks';
import { useTenant } from '../../hooks';
import { MdOutlineCancel } from "react-icons/md";
import { FaRegSave } from "react-icons/fa";
import { IoAddCircleOutline } from "react-icons/io5";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AiOutlineDelete } from 'react-icons/ai';
import ToggleSwitch from '../OsNewWeight/OsToggleSwitch';
import { getListPrice } from '../../store/ListPriceSlice';
import { useDispatch, useSelector } from 'react-redux';

const OsEditListName = ({ id, setMode }) => {
    const { data, loading, error, putData } = usePut();
    const { tenantId } = useTenant();
    const [name, setName] = useState("");
    const [isActive, setIsActive] = useState(false);
    const [errorMessages, setErrorMessages] = useState({});
    const [isModified, setIsModified] = useState(false); // حالة لتتبع التعديلات
    const [originalName, setOriginalName] = useState(""); // القيمة الأصلية لـ name
    const [originalIsActive, setOriginalIsActive] = useState(false); // القيمة الأصلية لـ isActive
    const { currentTenantId, currentCompanyId, currentBranchId } = useCurrent();
    const dispatch = useDispatch();
    const { ListData } = useSelector((state) => state.ListPrice);

    // تحميل البيانات عند تغيير الـ id
    useEffect(() => {
        if (id) {
            dispatch(getListPrice(`${process.env.REACT_APP_INV_API_SERVER_BASE_URL}/inventory/list-price/${id}`));
        }
    }, [dispatch, id]);

    // تعبئة الحقول عند تغيير ListData
    useEffect(() => {
        if (ListData && ListData.length > 0) {
            const selectedItem = ListData.find(item => item.id === id);
            if (selectedItem) {
                setName(selectedItem.name);
                setIsActive(selectedItem.is_active === 1);
                setOriginalName(selectedItem.name); // حفظ القيمة الأصلية لـ name
                setOriginalIsActive(selectedItem.is_active === 1); // حفظ القيمة الأصلية لـ isActive
                setIsModified(false); // إعادة تعيين isModified عند تحميل البيانات
            }
        }
    }, [ListData, id]);

    // عرض رسائل النجاح أو الخطأ
    useEffect(() => {
        if (data?.status === 1) {
            toast.success("تم الحفظ بنجاح", {
                style: {
                    backgroundColor: '#e5e7eb',
                    color: '#374151',
                    padding: '1rem',
                    borderRadius: '0.5rem',
                    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
                }
            });

            // تفريغ الحقول بعد الإضافة بنجاح
            setName("");
            setIsActive(false);
            setErrorMessages({});
            setIsModified(false); // إعادة تعيين isModified بعد الحفظ
        } else if (error) {
            toast.error("حدث خطأ أثناء الحفظ", {
                style: {
                    backgroundColor: '#e5e7eb',
                    color: '#374151',
                    padding: '1rem',
                    borderRadius: '0.5rem',
                    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
                }
            });
        }
    }, [data, error]);

    // التحقق من صحة النموذج
    const validateForm = () => {
        let errors = {};
        if (!name.trim()) errors.name = "اسم القائمة مطلوب";
        setErrorMessages(errors);
        return Object.keys(errors).length === 0;
    };

    // حفظ البيانات
    const handleSave = async () => {
        if (!tenantId) {
            toast.error("لم يتم العثور على tenantId", {
                style: {
                    backgroundColor: '#e5e7eb',
                    color: '#374151',
                    padding: '1rem',
                    borderRadius: '0.5rem',
                    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
                }
            });
            return;
        }

        if (!validateForm()) {
            toast.error("يرجى ملء جميع الحقول المطلوبة", {
                style: {
                    backgroundColor: '#e5e7eb',
                    color: '#374151',
                    padding: '1rem',
                    borderRadius: '0.5rem',
                    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
                }
            });
            return;
        }

        const payload = {
            name: name,
            is_active: isActive ? 1 : 0,
        };

        try {
            await putData(`${process.env.REACT_APP_INV_API_SERVER_BASE_URL}/inventory/list-price/${id}`, payload);
        } catch (error) {
            console.error("Error saving data:", error);
        }
    };

    // إلغاء العملية
    const handleCancel = () => {
        setMode('List');
    };

    // تحديث isModified عند تغيير الحقول
    const handleNameChange = (e) => {
        setName(e.target.value);
        checkIfModified(e.target.value, isActive); // التحقق من التعديلات
    };

    const handleIsActiveChange = (e) => {
        setIsActive(e.target.checked);
        checkIfModified(name, e.target.checked); // التحقق من التعديلات
    };

    // التحقق من وجود تعديلات
    const checkIfModified = (currentName, currentIsActive) => {
        const isNameModified = currentName !== originalName;
        const isActiveModified = currentIsActive !== originalIsActive;
        setIsModified(isNameModified || isActiveModified); // تحديث isModified بناءً على التعديلات
    };

    return (
        <div className="bg-gray-100 p-6 rounded-lg shadow-md">
            <ToastContainer
                toastClassName="bg-gray-100 text-gray-900"
                bodyClassName="bg-gray-100 text-gray-900"
                progressClassName="bg-gray-300"
            />
            <div className="w-full flex flex-row-reverse mb-6">
                <MwButton
                    actionType="button"
                    size="sm"
                    inGroup={true}
                    type="deleteBtn"
                    onClick={handleCancel}
                >
                    <AiOutlineDelete className="ml-1" />
                    إلغاء
                </MwButton>
                <MwButton
                    actionType="button"
                    size="sm"
                    inGroup={true}
                    type="original-right"
                    onClick={handleSave}
                    disabled={loading || !isModified} // تعطيل الزر إذا لم يتم التعديل
                >
                    {loading ? (
                        <span>جار الحفظ...</span>
                    ) : (
                        <>
                            <FaRegSave />
                            <span>حفظ</span>
                        </>
                    )}
                </MwButton>
            </div>

            <div className="gap-4 flex justify-between mb-4">
                <div className="block w-[50%]">
                    <label htmlFor="name" className="block text-sm text-gray-600 mb-1">اسم القائمة</label>
                    <input
                        id="name"
                        type="text"
                        value={name}
                        onChange={handleNameChange} // استخدام الدالة الجديدة
                        className="w-full p-2 border border-gray-300 rounded-md"
                    />
                    {errorMessages.name && <p className="text-red-500 text-sm">{errorMessages.name}</p>}
                </div>
                <div className="block w-[50%] mt-2">
                    <label htmlFor="isActive" className="block text-sm text-gray-600 mb-1">الحالة</label>
                    <ToggleSwitch
                        id="isActive"
                        label="نشط"
                        checked={isActive}
                        onChange={handleIsActiveChange} // استخدام الدالة الجديدة
                    />
                </div>
            </div>
        </div>
    );
};

export default OsEditListName;