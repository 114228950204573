import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
 import { MwButton } from '../ui';
import { AiOutlineDelete } from "react-icons/ai";
import { MdOutlineEdit } from "react-icons/md";
import { CiViewList } from "react-icons/ci";
import { IoAddCircleOutline } from "react-icons/io5";
import { useCurrent, useDelete, useTenant } from '../../hooks';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DeleteConfirmationModal from './../OsNewWeight/OsDeleteConfirmationModal ';
import { getListPrice } from '../../store/ListPriceSlice';

const OSviewAllListsPrices = ({ idSelected, setIdSelected, setMode }) => {
    const { currentTenantId, currentCompanyId, currentBranchId } = useCurrent();
    const navigate = useNavigate();
    const { tenant } = useParams();
    const dispatch = useDispatch();
    const { deleteItem } = useDelete();

    const { ListData, loading, error } = useSelector((state) => state.ListPrice);
    const units = Array.isArray(ListData) ? ListData : []; // التأكد من أن units مصفوفة
    console.log('ListData', ListData);

    const [menuVisible, setMenuVisible] = useState(null);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [selectedUnitId, setSelectedUnitId] = useState(null);

    useEffect(() => {
        if (currentTenantId && currentCompanyId && currentBranchId) {
            console.log("from list price");
            dispatch(getListPrice(`${process.env.REACT_APP_INV_API_SERVER_BASE_URL}/inventory/list-price/${currentTenantId}/${currentCompanyId}/${currentBranchId}`));
        }
    }, [dispatch, currentTenantId, currentCompanyId, currentBranchId]);

    console.log('view all data', ListData);

    const handleEdit = (id) => {
        setIdSelected(id);
        setMode('EditList');
    };

    const handleDelete = (id) => {
        console.log('delete id', id);
        setSelectedUnitId(id);
        setDeleteModalOpen(true);
    };

    const handleConfirmDelete = () => {
        if (selectedUnitId) {
          // البحث عن الوحدة المحددة في مصفوفة units
          const selectedUnit = units.find(unit => unit.id === selectedUnitId);
      
          // التحقق من وجود عناصر داخل selectedUnit.items
          if (selectedUnit && selectedUnit.items && selectedUnit.items.length > 0) {
            toast.error("لا يمكن الحذف! يجب حذف العناصر الداخلية أولاً.", {
              style: {
                backgroundColor: '#e5e7eb',
                color: '#374151',
                padding: '1rem',
                borderRadius: '0.5rem',
                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
              }
            });
            setDeleteModalOpen(false)
            return; // إيقاف العملية إذا كانت هناك عناصر داخلية
          }
      
          // إذا لم تكن هناك عناصر داخلية، يتم تنفيذ الحذف
          deleteItem(`${process.env.REACT_APP_INV_API_SERVER_BASE_URL}/inventory/list-price/${selectedUnitId}`)
            .then(() => {
              toast.success("تم الحذف بنجاح!", {
                style: {
                  backgroundColor: '#e5e7eb',
                  color: '#374151',
                  padding: '1rem',
                  borderRadius: '0.5rem',
                  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
                }
              });
      
              setDeleteModalOpen(false);
              setSelectedUnitId(null);
              dispatch(getListPrice(`${process.env.REACT_APP_INV_API_SERVER_BASE_URL}/inventory/list-price/${currentTenantId}/${currentCompanyId}/${currentBranchId}`));
            })
            .catch((error) => {
              toast.error("حدث خطأ أثناء محاولة الحذف!", {
                style: {
                  backgroundColor: '#e5e7eb',
                  color: '#374151',
                  padding: '1rem',
                  borderRadius: '0.5rem',
                  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
                }
              });
              console.error('Error deleting unit:', error);
            });
        }
      };

    const handleView = (id) => {
        setIdSelected(id);
        setMode('DetailsLists');
    };

    const toggleMenu = (id) => {
        setMenuVisible((prev) => (prev === id ? null : id));
    };

    const AddUnit = () => {
        setMode('AddList');
    };

    if (loading) {
        return (
            <div className="flex justify-center items-center h-64">
                <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="bg-red-50 p-4 rounded-lg">
                <p className="text-red-600">حدث خطأ أثناء تحميل البيانات: {error?.message || 'خطأ غير معروف'}</p>
            </div>
        );
    }

    if (units.length === 0) {
        return (
            <div className="bg-gray-50 p-6 rounded-lg text-center flex flex-col items-center justify-center">
                <p className="text-gray-600 mb-4">لا توجد قوائم أسعار متاحة</p>
                <div>
                    <MwButton inGroup={true} type="saveBtn" onClick={AddUnit}>
                        <IoAddCircleOutline />
                        <span>إضافة قائمة جديدة</span>
                    </MwButton>
                </div>
            </div>
        );
    }

    return (
        <div className="bg-gray-100 p-6   rounded-lg shadow-md">
            <ToastContainer />
            <div className='mb-3 flex justify-between items-center'>
                <h2 className="text-xl font-bold mb-4">قائمة  الأسعار  </h2>
                <MwButton inGroup={true} type="saveBtn" onClick={AddUnit}>
                    <IoAddCircleOutline />
                    <span> إضافة قائمة  </span>
                </MwButton>
            </div>
            <div className="min-w-[600px]">
                {units.map((unit) => (
                    <div key={unit.id} className="flex justify-between items-center p-4 border bg-gray-200 hover:bg-gray-300 transition-all duration-300 border-gray-300 rounded-md shadow-sm mb-4">
                        <div>
                            <p className="font-semibold">{unit?.name || 'اسم غير متوفر'}</p>
                            <p className="text-sm text-gray-500">{unit?.items?.length || "لا يوجد منتجات"}</p>
                        </div>
                        <div className="flex items-center gap-4 relative">
                            <div className={`h-4 w-4 rounded-full ${unit.is_active ? 'bg-green-500' : 'bg-red-500'}`} title={unit.is_active ? 'نشط' : 'غير نشط'}></div>
                            <button className="text-gray-500 hover:text-gray-700" onClick={() => toggleMenu(unit.id)}>⋮</button>
                            {menuVisible === unit.id && (
                                <div className="absolute left-1 top-7 bg-white shadow-lg rounded-md p-2 z-10 border border-gray-300" onClick={(e) => e.stopPropagation()}>
                                    <button onClick={() => handleView(unit.id)} className="flex items-center gap-1 text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                                        <CiViewList />
                                        عرض
                                    </button>
                                    <button onClick={() => handleEdit(unit.id)} className="flex gap-1 items-center text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                                        <MdOutlineEdit />
                                        تعديل
                                    </button>
                                    <button onClick={() => handleDelete(unit.id)} className="items-center gap-1 flex text-left px-4 py-2 text-sm text-red-500 hover:bg-gray-100">
                                        <AiOutlineDelete />
                                        حذف
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                ))}
            </div>

            <DeleteConfirmationModal isOpen={deleteModalOpen} onClose={() => setDeleteModalOpen(false)} onConfirm={handleConfirmDelete} />
        </div>
    );
};

export default OSviewAllListsPrices;;;