import { useEffect, useState } from 'react';
import {  useTranslation } from 'react-i18next';
import  {MwSelectorProduct,MwInputText, MwTextArea, MwSelector} from '../ui'
import InputQty from '../ui/InputQty';
import {TiDeleteOutline} from 'react-icons/ti' 
import {LangArrayToObjKeyValue} from '../../utils/global.js';
import { useCurrent, useFetch, useInvoice } from '../../hooks';
import {amountFormat} from '../../utils/invoceHelper.js'
import { BiMessageSquareDetail } from "react-icons/bi";

const InvFormListItem = ({
    index,
    reloadProductList,
    showModalProductHandle,
    productList,
    setProductList,
    formErrors,
    SETTING_PRICE_INCLUDE_VAT,
    SETTING_INV_QTY_DAYS,
    SETTING_INV_UOM_SHOW,
    SETTING_SHOW_INVOICE_PRODUCT_DESCRIPTION
    }) => {
    const {currentLangId} = useCurrent();
    const [t] = useTranslation('global')
    const [pricelist,setPricelist]= useState({}); 
    const {SETTING_INVOICE, toFloat } = useInvoice();
    const [isProductDesc,setIsProductDesc] = useState(false);
    const isDisabledPriceUnit =false;
    const [haveUom,setHaveUom] = useState();
    const {data:uomData,loading:uomLoading,fetchData} = useFetch(`${process.env.REACT_APP_INV_API_SERVER_BASE_URL}/inventory/uom/${haveUom}`,false);
    const [uomList,setUomList] = useState([]);
    const [uomSaleSelected,setUomSaleSelected] = useState();
    useEffect(() => {
        if(uomSaleSelected){
            console.log('uomSaleSelected',index,uomSaleSelected)
            if(!!productList[index]){
                productList[index].uom = uomSaleSelected.value;
                setBalanceRow(index)
            }
        }
    },[uomSaleSelected]);
    useEffect(() =>{
        if(haveUom){
            fetchData()
        }
    },[haveUom])
    useEffect(() => {
        console.log('setUomList',uomData);
                if(uomData  ){
                    let itemList = [];
                    uomData?.data?.uom_master?.uom_details.map((el)=>itemList?.push({label:el?.short_name?.trim(),value:el?.id,lang:currentLangId,data:el}));
                    setUomList(itemList)
                    console.log('setUomList',itemList)
                    
                }
            },[uomData]);
    const descItem = () => {
        setIsProductDesc(!isProductDesc);
    }
    const deleteItem = () => {
        console.log(productList);
        
        const newFilter = productList.filter((item,i) => {
            if (item.index !== index){
                // item.productId = ''
                // item.productName = 'Deleted'
                // item.productDesc = 'Deleted'
                // item.qty = ''
                // item.qtyDays = ''
                // item.price = ''
                return item;
            }
        });
        // console.log(newFilter)
        setProductList(newFilter)
    }

    const onChangeProduct = (value) => {
        console.log('onChangeProduct---',value)
        //productList[index].uom = uomSaleSelected.value;
        value?.uomSale && setUomSaleSelected({label:value?.uomSale?.short_name?.trim(),value:value?.uomSale.id,lang:currentLangId,data:value?.uomSale})
        console.log('setUomSaleSelected',value?.uomSale)
        if(!value?._id){
            productList[index] = {
                index,
                productId:'0', 
                productName: value,
                productDesc: '',
                qty:1,
                qtyDays:1,
                price:'',
                
            }
            setPricelist({...pricelist});
            setBalanceRow(index)
            return 
        }
        const newPrice =  SETTING_PRICE_INCLUDE_VAT === 'true' ? value?.price * 100/115   : value?.price;
        pricelist[`${value?._id}`] = newPrice;
        setPricelist({...pricelist});
        //console.log('pricelist',pricelist);
        productList[index] = {
            index,
            productId:value?._id, 
            productName: LangArrayToObjKeyValue(value?.productName)?.[currentLangId] || value?.productName[1]?.text || value?.productName[0]?.text ,
            productDesc: LangArrayToObjKeyValue(value?.productName)?.[currentLangId] === value?.productName[0]?.text ? value?.productName[1]?.text : value?.productName[0]?.text ,
            qty:1,
            qtyDays: value?.qtyDays || 1, 
            price:newPrice,
            priceUnit:newPrice,
            uomTemplateId:value?.uomTemplate?.id
        }
        setUomList([])
        setHaveUom(value?.uomTemplate?.id)
        setProductList([...productList]); 
        setBalanceRow(index)
        console.log('productList =>',productList)
        
    };

    const incressQty = (index) => {
        if(!!productList[index] && parseInt(productList[index].qty) > 0){
            const newValue = parseInt(productList[index].qty)+ 1
            productList[index].qty = newValue;
            onChangeQty(newValue,index)
            setBalanceRow(index)
        }
    }
    const incressQtyDays = (index) => {
        if(!!productList[index] && parseInt(productList[index].qtyDays) > 0){
            const newValue = parseInt(productList[index].qtyDays)+ 1
            productList[index].qtyDays = newValue;
            onChangeQtyDays(newValue,index)
            setBalanceRow(index)
        }
        
    }
    const decressQty = (index) => {
        if(!!productList[index] && parseInt(productList[index].qty) > 1){
            const newValue = parseInt(productList[index].qty)- 1
            productList[index].qty = newValue;
            onChangeQty(newValue,index)
          //  setBalanceRow(index)
        }
        
    }
    const decressQtyDays = (index) => {
        if(!!productList[index] && parseInt(productList[index].qtyDays) > 1){
            const newValue = parseInt(productList[index].qtyDays)- 1
            productList[index].qtyDays = newValue;
            onChangeQtyDays(newValue,index)
          //  setBalanceRow(index)
        }
        
    }
    const onChangeQty = (value,index) => {
        if (productList[index]?.productId === '0' ) {
            
            productList[index].qty = value
            productList[index].price = productList[index].priceUnit * value
            setProductList([...productList]); 
            return;
        };
        // const priceOne = parseFloat( pricelist[`${productList[index].productId}`])  ;
        const priceOne = productList[index].priceUnit ;
        console.log('priceOne',priceOne);
        if(!(!!productList[index].qty)) {
            productList[index].qty=1
        }  
        if( !isNaN(productList[index].qty) && !isNaN(parseInt(productList[index].qty)) >= 1 ){
           // priceOne = (productList[index].price / productList[index].qty )
            productList[index].qty = value
            if((productList[index].qty * priceOne) &&  !isNaN(productList[index].qty * priceOne) ){
                if(productList[index]?.qtyDays){
                    productList[index].price  =  productList[index].qty * priceOne * productList[index]?.qtyDays
                }else{

                    productList[index].price  =  productList[index].qty * priceOne
                }
            }else{
                if(!isNaN(productList[index].price)){
                    productList[index].price  = priceOne
                }else{
                    //productList[index].price = 
                }
            }
            console.log('productList.qty',productList[index].qty,'price;',productList[index].price)
        }
        setProductList([...productList]); 
        setBalanceRow(index)
    }
    const onChangeQtyDays = (value,index) => {
        if (productList[index]?.productId === '0' ) {
            
            productList[index].qtyDays = value
            productList[index].price = productList[index].priceUnit * value * productList[index].qty
            setProductList([...productList]); 
            return;
        };
        // const priceOne = parseFloat( pricelist[`${productList[index].productId}`])  ;
        const priceOne = productList[index].priceUnit ;
        console.log('priceOne',priceOne);
        if(!(!!productList[index].qtyDays)) {
            productList[index].qtyDays=1
        }  
        if( !isNaN(productList[index].qtyDays) && !isNaN(parseInt(productList[index].qtyDays)) >= 1 ){
           // priceOne = (productList[index].price / productList[index].qty )
            productList[index].qtyDays = value
            if((productList[index].qty * priceOne) &&  !isNaN(productList[index].qtyDays * priceOne) ){
                productList[index].price  =  productList[index].qtyDays * productList[index].qty * priceOne
            }else{
                if(!isNaN(productList[index].price)){
                    productList[index].price  = priceOne
                }else{
                    //productList[index].price = 
                }
            }
            console.log('productList.qty_days',productList[index].qtyDays,'price;',productList[index].price)
        }
        setProductList([...productList]); 
        setBalanceRow(index)
    }

    const onChangePrice = (value,index) =>{
        productList[index].price = value ;
        setProductList([...productList]); 
    }
    const onChangeProductDesc = (value,index) =>{
        productList[index].productDesc = value ;
        setProductList([...productList]); 
    }
    const onChangeSection = (value,index) =>{
        productList[index].productName = value ;
        setProductList([...productList]); 
    }

    const onChangePriceUnit = (value,index) =>{
        productList[index].priceUnit = value ;
        if(productList[index].qty ){
            productList[index].price = productList[index].qty * value ;
        }
        setBalanceRow(index)
        setProductList([...productList]); 
    }
    const onChangeProductDiscount = (value,index) =>{
        if(value.includes('%')){
            value =  (toFloat( toFloat(productList[index].price,false) * toFloat(value.replace('%', ''),false) /100 ));
            
        }
        if(value > parseInt(productList[index].price || 0) ){
            productList[index].productDiscount = 0;
        }else{
            productList[index].productDiscount = value ;
        }
        if(productList[index].price ){
            setBalanceRow(index)
        }
        
        setProductList([...productList]); 
    }

    const setBalanceRow = (index) => {
      //  const _price = productList[index].price ;
        // const _productDiscount = productList[index].productDiscount || 0;
       // const _productNetTotal = productList[index].productNetTotal || 0 ; 

    //    if(productList[index]?.qtyDays ){
    //     productList[index].price  =  productList[index].price * productList[index]?.qtyDays
    //     }

        if( (productList[index].productNetTotal || 0) > productList[index].price ){
            productList[index].productDiscount = 0 ;
        }
        if(productList[index]?.productDiscount && productList[index]?.productDiscount?.includes('%')){
            productList[index].productNetTotal =  toFloat(productList[index].price,false) -( toFloat(productList[index].price,false) * productList[index].productDiscount.replace('%', '') /100 );
            
        }else{
            productList[index].productNetTotal = productList[index].price - (productList[index].productDiscount || 0);
        }
        productList[index].productVat = (productList[index].productNetTotal || 0) * SETTING_INVOICE.INV_VAT_PERCENT /100
        productList[index].productNetTotalWithVat = productList[index].productVat + (productList[index].productNetTotal || 0) ;
        setProductList([...productList]); 
        console.log('item numbers => ',productList[index].productNetTotal,productList[index].productVat,productList[index].productNetTotalWithVat)
    }

    useEffect(()=>{
        if(productList){
            
        }
    },[productList]);

    useEffect(()=>{
        console.log(SETTING_INVOICE)
        
    },[]);

    // const isDisabledPriceUnit = !(!!productList[index]?.productId && !!productList[index]?.productName ) || productList[index]?.productId != 0 ;
    
    return (
        <>
            {productList[index]?.productName !== 'Deleted' && 
            productList[index]?.productId === "section" ?
            <div className='flex border-b items-center py-2  '>
                <div className="w-full flex gap-1 items-center ">
                    <MwInputText onChange ={(e)=>onChangeSection(e.target.value,index)} value={productList[index]?.productName} classNameInput=" bg-slate-300 text-slate-850 font-bold " placeholder="ادخل اسم القسم" />
                    <TiDeleteOutline onClick={()=>deleteItem()} size={21} title='حذف القسم' className='text-red-500' />
                    </div>
            </div>
            :
            <div className=' flex  items-center py-2 mb-1 gap-2  bg-slate-100 rounded-lg p-2  '>
                <div className=' flex flex-col w-full  '>
                    <div className='flex items-center py-2  '>
                        <div className="flex flex-1 flex-col ">
                            <MwSelectorProduct 
                                invalid = {!!formErrors?.productList}
                                withLabel={index === 0 && true}
                                initalValue = {productList[index]?.productName}
                                reloadProductList={reloadProductList} 
                                onCreateProduct={showModalProductHandle} 
                                onChangeCallback ={onChangeProduct} />
                        </div>
                        <div className="  w-20 mx-1   ">
                            <MwInputText 
                                label={index === 0 && 'سعر الوحدة'}
                                disabled = {isDisabledPriceUnit}
                                id='priceUnit' 
                                value={ isDisabledPriceUnit ?amountFormat(productList[index]?.priceUnit) : productList[index]?.priceUnit || ''} 
                                onChange={(e)=>onChangePriceUnit(e.target.value,index)} />
                        </div>
                        {SETTING_INV_QTY_DAYS === 'true' && <div className={ `flex flex-col me-1 ${index === 0 && 'mt-1' } `}>
                            <InputQty 
                                label= {index === 0 && t('invoice.quantity_days') }
                                id='qty_days' 
                                value={productList[index]?.qtyDays || ''} 
                                onChange={(e)=>onChangeQtyDays(e.target.value,index)}
                                onIncress={(e)=>incressQtyDays(index)}
                                onDecress={(e)=>decressQtyDays(index)}

                                // disabled={true}
                                />
                                
                                
                        </div>}
                        <div className={ `relative flex flex-row  `}>
                            <div className={ ` flex flex-col me-1 ${index === 0 && 'mt-1' } `}>

                                <InputQty 
                                    label= {index === 0 && t('invoice.quantity') }
                                    id='qty' 
                                    // disabled = {!(!!productList[index]?.productId)}
                                    value={productList[index]?.qty || ''} 
                                    onChange={(e)=>onChangeQty(e.target.value,index)}
                                    onIncress={(e)=>incressQty(index)}
                                    onDecress={(e)=>decressQty(index)}
                                    // custClassName={SETTING_INV_UOM_SHOW === 'true' && uomList.length > 0 &&  'text-center text-bold '}
                                    width={(SETTING_INV_UOM_SHOW === 'true' && uomList.length === 0) ?  '38' : '24'}
                                    // disabled={true}
                                    />
                            </div>
                                {
                                    SETTING_INV_UOM_SHOW === 'true' && uomList.length > 0 && 
                                        
                                        <MwSelector
                                            initalValue='الوحدة'
                                            dataType = 'labelValue'
                                            _data={uomList}
                                            withAddNew = {false}
                                            selectedItem={uomSaleSelected}
                                            setSelectedItem={setUomSaleSelected}
                                            className={`z-20 w-16 ${index === 0 && 'pt-5 '} me-1`}
                                            withSearch={false}
                                        />
                                }
                        </div>
                        <div className="w-20  ">
                            <MwInputText 
                                label={`${index === 0 ? t('invoice.price')  : ''}`}
                                id='price' 
                                disabled={ true}
                                value={ amountFormat(productList[index]?.price) || ''} 
                                onChange={(e)=>onChangePrice(e.target.value,index)} />
                        </div>
                        <div className=" w-16 mx-1 ">
                            <MwInputText 
                                label={index === 0 && 'الخصم'}
                                id='productDiscount' 
                                value={ productList[index]?.productDiscount } 
                                onChange={(e)=>onChangeProductDiscount(e.target.value,index)} />
                        </div>
                        <div className=" w-20   ">
                            <MwInputText 
                                label={index === 0 && 'الإجمالي'}
                                disabled={ true}
                                id='productNetTotal' 
                                value={ amountFormat(productList[index]?.productNetTotal) } 
                                onChange={(e)=>onChangePrice(e.target.value,index)} />
                        </div>
                        <div className=" w-20  mx-1 ">
                            <MwInputText 
                                disabled={ true}
                                label={index === 0 &&  ` الضريبة  ${SETTING_INVOICE.INV_VAT_PERCENT}%`}
                                id='productVat' 
                                value={ amountFormat(productList[index]?.productVat) } 
                                onChange={(e)=>onChangePrice(e.target.value,index)} />
                        </div>
                        <div className="w-24">
                            <MwInputText 
                                disabled={ true}
                                label={index === 0 && 'الصافي'}
                                id='productNetTotalWithVat' 
                                value={ amountFormat(productList[index]?.productNetTotalWithVat) } 
                                onChange={(e)=>onChangePrice(e.target.value,index)} />
                        </div>
                    </div>
                    { SETTING_SHOW_INVOICE_PRODUCT_DESCRIPTION === 'true' && isProductDesc &&
                    <div className="w-full">
                        <MwTextArea
                            id='productDescription' 
                            value={ productList[index]?.productDesc } 
                            onChange={(e)=>onChangeProductDesc(e.target.value,index)} />
                    </div>
                    }
                </div>
                { 
                SETTING_SHOW_INVOICE_PRODUCT_DESCRIPTION === 'true' &&
                <div className={ `flex justify-center items-center w-8 h-8   bg-slate-200 rounded-lg ${index === 0 ? 'mt-7' : '' } `}>
                    {productList[index]?.price && <BiMessageSquareDetail onClick={()=>descItem()} size={21} title='تفاصيل المنتج' className='text-slate-800' /> }
                </div>
                }
                <div className={ `flex justify-center items-center w-8 h-8   bg-red-100 rounded-lg ${index === 0 ? 'mt-7' : '' } `}>
                    {productList[index]?.price && <TiDeleteOutline onClick={()=>deleteItem()} size={21} title='حذف المنتج' className='text-red-500' /> }
                </div>

            </div>}
        </>
    )
}

export default InvFormListItem