import { useState } from "react";
import { LuLayoutDashboard } from "react-icons/lu";
import UnitsNavbar from "../../components/Products/tag/OsUnitsNavBar";
import OSALLCAT from "./OSALLCAT";
import OSListsPrices from "./OSListsPrices";

const OsWeightSetting = () => {
    const [tabActive, setTabActive] = useState('Units');

    return (
        <>
            <div className='flex justify-between items-center pb-3'>
                <div className='flex gap-1 px-2 text-sm text-gray-400'>
                    <LuLayoutDashboard size={18} />
                    إعدادات المنتجات والخدمات
                </div>
            </div>

            <UnitsNavbar tabActive={tabActive} setTabActive={setTabActive} />

            <div className='bg-slate-50 p-2 pt-5 rounded-b-lg'>
                {tabActive === 'Units' && (
                    <div className='flex flex-col gap-3'>
                        <OSALLCAT />
                    </div>
                )}

                {tabActive === 'Sales' && (
                    <div className='flex flex-col gap-3'>
                        <OSListsPrices />
                    </div>
                )}
            </div>
        </>
    );
}

export default OsWeightSetting;