import { useInvoice,useCurrent } from '../../hooks';
import {LangArrayToObjKeyValue} from '../../utils/global'
import {AiOutlineEdit} from 'react-icons/ai'
import {RiDeleteBin6Line} from 'react-icons/ri'
import { SiSitecore } from "react-icons/si";

const ListProductsItems = ({item,editHandeler,deleteHandeler,index}) => {
    const { currentLangId} = useCurrent();
    const {SETTING_INVOICE,formatter} = useInvoice();
    const INV_CURRENCY = SETTING_INVOICE?.INV_CURRENCY
    const SETTING_INV_CURRENCY = INV_CURRENCY  || 'SAR'
    const productName = LangArrayToObjKeyValue(item?.productName)?.[currentLangId] || item?.productName[0]?.text || item?.productName[1]?.text
    let productName2 =""
    let productDesc =""
    if(productName === item?.productName[0]?.text){
        productName2 = item?.productName[1]?.text
        productDesc = item?.productDesc[0]?.text
    }else{
        productName2 = item?.productName[0]?.text
        productDesc = item?.productDesc[1]?.text
    }
    return (
        <>
        {
            index === 0 && 
            <div className='flex mt-2 gap-3 bg-slate-200 font-bold rounded-t-2xl justify-between items-center border-t py-2 px-2 hover:bg-indigo-50 hover:font-bold'>
                <div className='px-2'>#</div>
                <div className='w-28 flex justify-center items-center  '>كود المنتج</div>
                <div className='flex-1 flex gap-5 justify-between'>المنتج</div>
                <div className='w-16 p-2  text-center'>الكمية</div>
                <div className='w-24 text-center'> سعر البيع </div>
                <div className={` w-24 text-center   pb-1 rounded-lg `}> الموقع الالكتروني </div>
                <div className={` w-20 text-center px-2 pb-1 rounded-lg`} >الحالة</div>
                <div className='w-8 text-center text-indigo-400 cursor-default hover:text-indigo-800  '></div>
                <div className='w-8 text-center text-indigo-400 cursor-default hover:text-indigo-800  ' > </div>
            </div>
        }
            <div className='flex gap-3 justify-between items-center border-t py-2 px-2 hover:bg-indigo-50 hover:font-bold'>
                <div className='px-2'>{index+1}</div>
                <div className='w-28 flex justify-center items-center  '>{ item?.productCode && item?.productCode}</div>
                <div className='flex-1 flex gap-5 justify-between'>
                    <div className='flex-1   '>
                        <div className='flex-1 flex gap-5 items-center justify-between'> 
                            <div>{productName} </div>
                        </div>
                        { productName2 && <div className='text-xs text-slate-400'>   {productName2}  </div> }
                        { productDesc && <div className='text-xs text-slate-400' >{productDesc}</div> }
                    </div>
                    <div className=' text-xs font-bold text-slate-500 p-1'>
                        { item?.productBrand &&
                            <span className='bg-sky-100 rounded-lg px-2  mx-1'>
                                {LangArrayToObjKeyValue(item?.productBrand?.brandName)?.[currentLangId]   || item?.productBrand?.brandName[0]?.text ||      item?.productBrand?.brandName[1]?.text  }     
                            </span>
                        }
                        { item?.productCategory[0] && 
                            <span className='bg-indigo-100 rounded-lg px-2  mx-1'>
                                { LangArrayToObjKeyValue(item?.productCategory[0]?.categoryName)?.[currentLangId]   || item?.productCategory[0]?.categoryName[0]?.text ||      item?.productCategory[0]?.categoryName[1]?.text  }       
                            </span> 
                        }
                    </div>
                </div>
                <div className='w-16 p-2 text-center '>{item?.qty &&  item?.qty}</div>
                <div className='w-24 text-center'>{formatter.format(item.price)} </div>
                <div className={` w-24 text-center px-2 pb-1 rounded-lg flex items-center justify-center `}>{item.isShowOnWebsite ? <SiSitecore size={18} title='يظهر علي الموقع الالكتروني' color='green' /> : <SiSitecore size={18} title='لا يظهر علي الموقع الالكتروني' color='grey' />}</div>
                <div className={` w-20 text-center px-2 pb-1 rounded-lg   ${item.isActive ? ' bg-green-200 ' : 'bg-slate-200' }`}>{item.isActive ? 'مفعل' : 'غير مفعل'}</div>
                <div className='w-8 text-center text-indigo-400 cursor-default hover:text-indigo-800  ' onClick={()=>editHandeler(item._id)}><AiOutlineEdit size={18}/></div>
                <div className='w-8 text-center text-indigo-400 cursor-default hover:text-indigo-800  ' onClick={()=>deleteHandeler(item._id)}><RiDeleteBin6Line size={18} /></div>
            </div>
        </>
    )
}

export default ListProductsItems