import React, { useEffect, useState } from 'react'
import {  useTranslation } from 'react-i18next';

import { MwInputText,MwTextArea,MwSwitch, MwSelectorMulti, MwSelector } from '../../ui'
import FormLangBar from '../../FormLangBar'
import { useFetch } from '../../../hooks';
import {LangArrayToObjKeyValue} from '../../../utils/global';

const BasicInfoForm = ({
    _salesmansList,
    _setSalesmanList,
    custTypesList,
    customerType,
    setCustomerType,
    salesmanSelected,
    setSalesmanSelected,
    currentTenantId,
    currentCompanyId,
    currentBranchId,
    currentLangId,
    currentLangList,
    errors,
    customerNo,
    setCustomerNo,
    groupSelected,
    setGroupSelected,
    fullname,
    setFullname,
    mobileNo,
    setMobileNo,
    phoneNo,
    setPhoneNo,
    email,
    setEmail,
    customerNotes,
    setCustomerNote,
    isActive,
    setIsActive

}) => {

    const [t] = useTranslation('global')
    const [formLang,setFormLang] = useState(currentLangId);
    const {data:dataCustomersGroups,loading:loadingGroups} = useFetch(`${process.env.REACT_APP_Auth_API_BASE_URL}/customers/groups/${currentTenantId}/${currentCompanyId}/${currentBranchId}`);
    const [groupsList,setGroupsList] = useState();

    useEffect(()=>{
        if(dataCustomersGroups && !loadingGroups)
        {
           //console.log(dataCustomersGroups);
            const groupsHandler = dataCustomersGroups.data.map((el) => {
                const label = LangArrayToObjKeyValue(el.groupName);
                return {
                    label: label?.[currentLangId],
                    value: el._id,
                    lang: currentLangId
                }
            });
            
            setGroupsList(groupsHandler)
        }
    },[dataCustomersGroups]);

    return (
        <>
            <div className='flex gap-4'>
                <div className='py-2 pt-8 w-38'>
                    <MwInputText 
                        label='رقم العميل'
                        id='customerNo' 
                        value={customerNo} 
                        onChange={(e)=>setCustomerNo(e.target.value)} />
                </div>
                <div className='flex-1 py-2'>
                    <FormLangBar currentLangList = {currentLangList} formLang = {formLang} setFormLang = {setFormLang} />
                    { currentLangList.map((lang) => (
                    <div key={lang.langCode} className=''>
                        <div className={` ${ formLang === lang?.langCode ? 'block' : 'hidden' } `}>
                            <MwInputText 
                                label={`اسم العميل (${lang?.langName})`}
                                id={`customerName${lang?.langCode}`} 
                                value={fullname[lang?.langCode]}
                                invalid={!!errors[`fullname.${lang?.langCode}`] }
                                invalidMsg = {errors[`fullname.${lang?.langCode}`]}
                                onChange={(e)=>{
                                    setFullname({...fullname, [lang?.langCode]:e.target.value});
                                }}
                                />
                        </div>
                    </div>
                    )) }
                </div>
                
            </div>
            
            <div className='flex gap-4'>
                <div className='py-2 flex-1'>
                    <MwSelector
                        label={'نوع العميل'}
                        initalValue='اختار نوع العميل'
                        dataType='labelValue'
                        _data = {custTypesList}
                        withAddNew = {false}
                        withSearch = {false}
                        selectedItem={customerType}
                        setSelectedItem={setCustomerType}
                        />
                </div>
                <div className="py-2 flex-1 flex flex-col ">
                    <MwSelector
                        withAddNew={false}
                        initalValue={`${t('invoice.selectSalesman')}...`}
                        label={t('invoice.selectSalesman')}
                        _data={_salesmansList} 
                        dataType='salesman' 
                        selectedItem = {salesmanSelected}
                        setSelectedItem = {setSalesmanSelected}
                    />
                </div>
                <div className='flex-1 py-2 relative'>
                    {
                    groupsList && <MwSelectorMulti
                                    label={`مجموعة العميل`}
                                    initalValue = {`اختار مجموعه العميل`}
                                    _data={groupsList}
                                    dataType='labelValue'
                                    withAddNew = {false}
                                    setSelectedItem = {setGroupSelected}
                                    selectedItem = {groupSelected}
                                    />
                    }
                </div>
            </div>
            
            <div className='flex gap-4'>
                <div className='flex-1 py-2'>
                    <MwInputText 
                        label='رقم الهاتف '
                        id='phoneNo' 
                        inputType='Number'
                        value={phoneNo} 
                        invalid={!!errors.phoneNo}
                        invalidMsg={errors.phoneNo}
                        onChange={(e)=>setPhoneNo(e.target.value)} />
                </div>
                <div className='flex-1 py-2'>
                    <MwInputText 
                        label='رقم الجوال '
                        id='mobileNo' 
                        inputType='Number'
                        value={mobileNo} 
                        invalid={!!errors.phoneNo}
                        invalidMsg={errors.phoneNo}
                        onChange={(e)=>setMobileNo(e.target.value)} />
                </div>
                <div className='flex-1 py-2'>
                    <MwInputText 
                        label='البريد الالكتروني'
                        id='email' 
                        inputType='email'
                        value={email} 
                        onChange={(e)=>setEmail(e.target.value)} />
                </div>
            </div>
            <div className='py-2'>
                <MwTextArea 
                    label='ملاحظات'
                    id='customerNotes' 
                    value={customerNotes} 
                    rows={2}
                    onChange={(e)=>setCustomerNote(e.target.value)} />
            </div>
            <div className='flex justify-between items-center py-4   mb-2'>
                    <label className='text-xs text-slate-400'>مفعل / غير مفعل</label>
                    <MwSwitch custKey='isActive' isActive={isActive} setIsActive={setIsActive} onChangeSwitch={() => setIsActive(!isActive)} />
            </div>
        </>
    )
}

export default BasicInfoForm