import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import MwButton from "../ui/MwButton";
import { getUOMById } from "../../store/weightSlice";
import { AiOutlineArrowRight, AiOutlineDelete } from "react-icons/ai";
import { MdOutlineEdit } from "react-icons/md";
import { useDelete } from "../../hooks";
import DeleteConfirmationModal from "./OsDeleteConfirmationModal ";

const OsUnitDetails = ({ id, setMode }) => {
    const dispatch = useDispatch();
    const { deleteItem } = useDelete();
    const { uomByIdData, loading, error } = useSelector((state) => state.weight);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);

    useEffect(() => {
        dispatch(getUOMById(id));
    }, [dispatch, id]);

    const handleDelete = () => {
        setDeleteModalOpen(true);
    };
    const handleBack= () => {
        setMode('List');
    };

    const handleConfirmDelete = () => {
        deleteItem(`${process.env.REACT_APP_INV_API_SERVER_BASE_URL}/inventory/uom/${id}`)
            .then(() => {
                setDeleteModalOpen(false);
                setMode('List'); // العودة إلى قائمة الوحدات بعد الحذف
            })
            .catch((error) => {
                console.error("Error deleting unit:", error);
            });
    };

    const handleEdit = () => {
        setMode('Edit'); 
    };

    if (loading) return <p className="text-center">يتم تحميل البيانات...</p>;
    if (error) return <p className="text-center text-red-500">حدث خطأ: {error}</p>;
    if (!uomByIdData || !uomByIdData.data) return <p className="text-center">الوحدة غير موجودة...</p>;

    const { uom_master } = uomByIdData.data;

    return (
        <div className="bg-gray-50 p-6 h-screen flex flex-col">
<div className="w-full flex flex-row-reverse justify-between   ">
    <div className="w-full flex flex-row-reverse  mb-6"><MwButton 
        actionType="button" 
        size="sm" 
        inGroup={true} 
        type="deleteBtn" 
        onClick={handleDelete}
    >
        <AiOutlineDelete className="ml-1" />
        حذف
    </MwButton>

    <MwButton 
        actionType="button" 
        size="sm" 
        inGroup={true} 
        type="secondary" 
        onClick={handleEdit}
    >
        <MdOutlineEdit className="ml-1" />
        تعديل
    </MwButton>
</div>
    <div>
           
    <MwButton 
        actionType="button" 
        size="sm" 
        inGroup={true} 
        type="circular-back" 
        onClick={handleBack}
    >
        <AiOutlineArrowRight className="ml-1" />
        عودة
    </MwButton>
    </div>
 
</div>



            <h2 className="text-2xl font-bold mb-8 text-center text-gray-800">{uomByIdData?.data?.name}</h2>

            <div className="overflow-x-auto mb-8 w-full max-w-4xl">
                <table className="table-auto w-full border-collapse border border-gray-300">
                    <thead>
                        <tr className="bg-gray-100 text-gray-800">
                            <th className="border border-gray-300 px-4 py-2 text-center">الحقل</th>
                            <th className="border border-gray-300 px-4 py-2 text-center">القيمة</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="border border-gray-300 px-4 py-2">اسم الوحدة</td>
                            <td className="border border-gray-300 px-4 py-2">{uom_master?.name}</td>
                        </tr>
                        <tr>
                            <td className="border border-gray-300 px-4 py-2">اسم مختصر</td>
                            <td className="border border-gray-300 px-4 py-2">{uom_master?.short_name}</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className="overflow-x-auto w-full max-w-4xl">
                <table className="table-auto w-full border-collapse border border-gray-300">
                    <thead>
                        <tr className="bg-gray-100 text-gray-800">
                            <th className="border border-gray-300 px-4 py-2 text-center">اسم الوحدة</th>
                            <th className="border border-gray-300 px-4 py-2 text-center">معدل التحويل</th>
                            <th className="border border-gray-300 px-4 py-2 text-center">اسم مختصر</th>
                        </tr>
                    </thead>
                    <tbody>
                        {uom_master?.uom_details?.length > 0 ? (
                            uom_master.uom_details.map((detail, index) => (
                                <tr key={index}>
                                    <td className="border border-gray-300 px-4 py-2">{detail.name}</td>
                                    <td className="border border-gray-300 px-4 py-2">{detail.rate}</td>
                                    <td className="border border-gray-300 px-4 py-2">{detail.short_name}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="3" className="border border-gray-300 px-4 py-2 text-center text-gray-500">لا توجد تفاصيل للوحدات</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
{/* 
            <div className="mt-8">
                <MwButton 
                    actionType="button" 
                    inGroup={true} 
                    type="saveBtn" 
                    onClick={() => setMode('List')} // العودة إلى قائمة الوحدات
                >
                    العودة
                </MwButton>
            </div> */}

            <DeleteConfirmationModal 
                isOpen={deleteModalOpen} 
                onClose={() => setDeleteModalOpen(false)} 
                onConfirm={handleConfirmDelete} 
            />
        </div>
    );
};

export default OsUnitDetails;